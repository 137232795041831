import React, { useEffect, useState } from 'react';
import { isEmpty, get } from 'lodash';
import { logInfo } from '../../../utils';
import useGenericErrors from './useGenericErrors';
import { useErrorStore } from '.';
import { useUserStore } from '../user';
import Dialog from '../../../commons/dialog/Dialog';

function useGenericErrorDialog (props = {}) {
    const {
        onCloseError = () => {},
        shouldSignout = true,
    } = props;

    const errStore = useErrorStore();
    const userStore = useUserStore();

    const {
        errors,
    } = useGenericErrors();

    const [errorOpen, setErrorOpen] = useState(false);
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');

    useEffect(() => {
        logInfo('');
        logInfo('');
        logInfo('useGenericErrorDialog() -> Errors Updated: ', errors);
        userStore.env.closeProgress();
        if (!isEmpty(errors)) {
            setTitle(errors.title);
            setMessage(errors.message);
            setErrorOpen(true);
        } else {
            setTitle('');
            setMessage('');
            setErrorOpen(false);
        }
    }, [errors]);

    function signOut () {
        if (!shouldSignout) {
            return;
        }

        const settings = get(userStore, 'env.settings');
        if (settings.path.indexOf('login') !== -1 ||
            settings.path.indexOf('signup') !== -1 ||
            settings.path.indexOf('superadmin') !== -1
        ) {
            window.location = '/login';
            return;
        }

        console.log('Env Setting: ', userStore.env.settings);
        if (errors.errCode === 'INVALID_ACCESS_TOKEN' || errors.errCode === 'PUBLISHER_INACTIVE') {
            userStore.env.showProgress('');
            userStore.signOut();
        }
    }

    function renderErrorDialog () {
        return (
            <Dialog
                openDialog={errorOpen}
                buttonSecondaryFlag={false}
                buttonPrimary={'Ok'}
                showSubtitle={true}
                subtitle={'Ref.ID: ' + errStore.env.traceId}
                handleAgree={() => {
                    setErrorOpen(false);
                    userStore.env.closeProgress();
                    if (typeof onCloseError === 'function') {
                        onCloseError();
                    }
                    signOut();
                }}

                closeModal={() => {
                    userStore.env.closeProgress();
                    setErrorOpen(false);
                    signOut();
                }}

                title={title}
                dialogContextText={message}
            />
        );
    }

    return {
        title,
        message,
        errorOpen,
        setErrorOpen,
        renderErrorDialog,
    };
}

export default useGenericErrorDialog;
