import {
    postGQL,
    endpoints,
    get,
} from '../../api';

import {
    queryGetButtons,
    queryAddWidget,
    queryUpdateWidgets,
    queryDeleteWidgets,
 } from './gql_queries';

export function apiGetButtons ({ type, draft, page, pageLimit, token, traceId }) {
    window.envStore.errorStore.gqlAction = 'getWidgets';
    const input = {};
    input.type = type;
    if (draft !== undefined) {
        input.draft = draft;
    }

    const sortOpt = {
        field: 'created',
        order: 'DESC',
    };

    return postGQL(
        endpoints().buttons,

        queryGetButtons({
            i: input,
            s: sortOpt,
            p: page,
            l: pageLimit,
        }),

        {
            token,
            traceId,
        }
    );
}

export function apiAddWidget ({ widget, token, traceId }) {
    window.envStore.errorStore.gqlAction = 'addWidget';
    return postGQL(
        endpoints().buttons,

        queryAddWidget({
            i: widget,
        }),

        {
            token,
            traceId,
        }
    );
}

export function apiEditWidgets ({ widgets, token, traceId }) {
    window.envStore.errorStore.gqlAction = 'updateWidgets';
    return postGQL(
        endpoints().buttons,

        queryUpdateWidgets({
            i: widgets,
        }),

        {
            token,
            traceId,
        }
    );
}

export function apiDeleteWidgets ({ widgets, token, traceId, type }) {
    window.envStore.errorStore.gqlAction = 'deleteWidgets';
    return postGQL(
        endpoints().buttons,
        queryDeleteWidgets({
            d: {
                type,
                widgetIds: widgets,
            },
        }),

        {
            token,
            traceId,
        }
    );
}

export function apiGetSubscribersByWidget (options) {
    return get(
        endpoints().subscribersByWidget,
        options
    );
}

export function apiGetSubscribersByEventDisplay (options) {
    return get(
        endpoints().subscribersByEventDisplay,
        options
    );
}
