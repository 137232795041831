import { isEmpty } from 'lodash';
import { v4 as uuid } from 'uuid';
import { getWhiteLabelStaticSettings as whiteLabelSettings } from '../styles/actions';

export const GenericErrors = [
    'INVALID_ACCESS_TOKEN',
    'INSUFFICIENT_ACCESS_PRIVILEGE',
    'INTERNAL_SERVER_ERROR',
    'PUBLISHER_INACTIVE',
    // 'GENERIC_ERROR',
];

export const errorMap = {
    // INVALID_ACCESS_KEY: {
    //     title: 'service error',
    //     message: 'Invalid Public Key. Please check the public key used.',
    // },

    INVALID_ACCESS_TOKEN: {
        errCode: 'INVALID_ACCESS_TOKEN',
        title: 'Session expired',
        message: 'You will be redirected to login page.',
    },

    TOKEN_EXPIRED: {
        errCode: 'INVALID_ACCESS_TOKEN',
        title: 'Session expired',
        message: 'You will be redirected to login page.',
    },

    TOKEN_ERROR: {
        errCode: 'INVALID_ACCESS_TOKEN',
        title: 'Session expired',
        message: 'You will be redirected to login page.',
    },

    INTERNAL_SERVER_ERROR: {
        errCode: 'INTERNAL_SERVER_ERROR',
        title: 'Internal Server Error',
        message: 'Internal Server Error',
    },

    INSUFFICIENT_ACCESS_PRIVILEGE: {
        title: 'insufficient access privilege',
        message: 'returned 400 error with reason: jwt token had expired.',
    },

    INVALID_INPUT_PARAMETER: (e) => {
        return {
            title: 'Error occurred!',
            message: 'incorrect image size for calendar logo.',
        };
    },

    UNPROCESSABLE_ENTITY: {
        title: 'Error occurred!',
        message: 'Enter valid value',
    },

    SIGNUP_FAILED_ECAL: {
        title: 'Sign Up Failed ',
        message: 'Email is already registered. Please try logging in.',
    },

    SIGNUP_FAILED_COGNITO: {
        title: 'Sign Up Failed ',
        message: 'Email is already registered. Please try logging in.',
        helperText: '', /* for fields */
    },

    USER_NOT_FOUND_EXCEPTION: (e) => {
        return {
            email: 'Please check your email',
            password: 'Please check your password',
        };
    },

    NOT_AUTHORIZED_EXCEPTION: (e) => {
       return {
            email: 'Please check your email',
            password: 'Please check your password',
            code: e.message,
       };
    },

    EXPIRED_CODE_EXCEPTION: {
        code: 'Verificaiton code had expired.',
    },

    INVALID_PASSWORD_EXCEPTION: {
        code: '',
        password: 'Password did not conform with policy.',
    },

    INVALID_PARAMETER_EXCEPTION: {
        code: '',
        password: 'Password did not conform with policy.',
    },

    CODE_MISMATCH_EXCEPTION: (e) => {
        let msg = e.message;
        if (msg.length > 1) {
            msg = msg.slice(0, -1);
        }
        return {
            code: msg,
            password: '',
        };
    },

    LIMITED_EXCEEDED_EXCEPTION: {
        emailVerify: 'Attempt limit exceeded, please try after some time.',
    },

    INVALID_ASSETS: (e, opts) => {
        if (opts.type === 'cal_logo') {
            return {
                image: e.message[0],
            };
        }
        return {
            message: e.message[0],
        };
    },

    MISSING_SCOPE: () => {
        const { supportContact } = whiteLabelSettings();
        return {
            title: 'Internal Server Error',
            message: `We are unable to authenticate your request. Some information is missing from your account. Please contact us at ${supportContact} for futher assistance.`,
        };
    },

    PUBLISHER_INACTIVE: (e) => {
        return {
            errCode: 'PUBLISHER_INACTIVE',
            title: 'Publisher Account Inactive',
            message: 'This publisher account is inactive, please contact ECAL support',
        };
    },

    defaultError: (err) => {
        return {
            errCode: 'UNHANDLED_GENERIC_ERROR',
            title: 'Oops!',
            message: err.message || 'Something went wrong. Our technical team are looking into the problem. Please try again later.',
        };
    },
};

export const ErrorAPIMessages = {
    TOKEN_ERROR: 'jwt verification failed',
    TOKEN_EXPIRED: 'Token is expired',
    INTERNAL_SERVER_ERROR: 'internal Server Error',
    SIGNUP_FAILED_ECAL: 'PreSignUp failed with error email exist on the record',
    SIGNUP_FAILED_COGNITO: 'An account with the given email already exists.',
    CODE_MISMATCH_EXCEPTION: 'Invalid verification code provided, please try again.',
    INVALID_ASSETS: 'incorrect image size for calendar logo.',
    PUBLISHER_INACTIVE: 'publisher account is inactive, please contact ECAL support',
    UNVERIFIED_ACCOUNT: 'Cannot reset password for the user as there is no registered/verified email or phone_number',
};

export const ErrorAPIReasons = {
    INTERNAL_SERVER_ERROR: 'INTERNAL_SERVER_ERROR',
    INSUFFICIENT_ACCESS_PRIVILEGE: 'INSUFFICIENT_ACCESS_PRIVILEGE',
};

export const ErrorAPICode = {
    USER_NOT_FOUND_EXCEPTION: 'UserNotFoundException',
    NOT_AUTHORIZED_EXCEPTION: 'NotAuthorizedException',
    EXPIRED_CODE_EXCEPTION: 'ExpiredCodeException',
    INVALID_PARAMETER_EXCEPTION: 'InvalidParameterException',
    INVALID_PASSWORD_EXCEPTION: 'InvalidPasswordException',
    LIMITED_EXCEEDED_EXCEPTION: 'LimitExceededException',
    CODE_MISMATCH_EXCEPTION: 'CodeMismatchException',
    MISSING_SCOPE: 'MissingCustomScope',
};

export const ErrorAPIErrors = {
    INVALID_INPUT_PARAMETER: 'INVALID_INPUT_PARAMETER',
    INVALID_ACCESS_TOKEN: 'INVALID_ACCESS_TOKEN',
};

/*
 * Unfortunately, we can't have one source of truth on handling error
 * we can't just rely on code, reasons or messages.
 *
 * This Function will validate if response is error or not
 * It will return the correct mapping if error is ok
 * else will return null.
 *
 * This function will check if the API response is an error, by  validating its code, messages or reason.
 */
export function validateError (err, options) {
    let mapResult = mapByCode(err, options);
    if (mapResult !== null) {
        return mapResult;
    }

    mapResult = mapByMessages(err, options);
    if (mapResult !== null && mapResult !== undefined) {
        return mapResult;
    }

    mapResult = mapByReason(err, options);
    if (mapResult !== null && mapResult !== undefined) {
        return mapResult;
    }

    mapResult = mapByError(err, options);
    if (!mapResult && !isEmpty(err)) {
        return null;// errorMap.defaultError();
    }

    return mapResult;
}

export function getMappedError (err, errProp, mapKeys, options) {
    if (!err) {
        return null;
    }

    let mapSource = errorMap;

    if (options && options.errorMap) {
         mapSource = options.errorMap;
    }

    function getErrObject (key) {
        let errObj = mapSource[key];
        if (typeof errObj === 'function') {
            errObj = errObj(err, options);
        }
        return errObj;
    }

    function isError (errTarget, value) {
        if (Array.isArray(errTarget)) {
            for (let i = 0; i < errTarget.length; i++) {
                const val = errTarget[i];
                if (val.indexOf(value) !== -1) {
                    return true;
                }
            }
        }
        if (errTarget.indexOf(value) !== -1) {
            return true;
        }
        return false;
    }

    for (const key in mapKeys) {
        const value = mapKeys[key];
        const errTarget = err[errProp];

        if (errTarget && typeof errTarget !== 'number' && value) {
            if (isError(errTarget.toLowerCase(), value.toLowerCase())) {
                return getErrObject(key);
            }
        }
    }
    return null;
}

export function mapByCode (err, opts) {
    return getMappedError(
        err,
        'code',
        (opts && opts.codes) ? opts.codes : ErrorAPICode,
        opts
    );
}

export function mapByMessages (err, opts) {
    return getMappedError(
        err,
        'message',
        (opts && opts.messages) ? opts.messages : ErrorAPIMessages,
        opts
    );
}

export function mapByError (err, opts) {
    const errObj = getMappedError(
        err,
        'error',
        (opts && opts.errors) ? opts.errors : ErrorAPIErrors,
        opts
    );
    return errObj;
}

export function mapByReason (err, opts) {
    return getMappedError(
        err,
        'reason',
        (opts && opts.reasons) ? opts.reasons : ErrorAPIReasons,
        opts
    );
}

export function getError (type, message) {
    const err = errorMap[type];
    if (message) err.message = message;
    return err;
}

export function serviceErrorSerializer (errors) {
    if (isEmpty(errors) || isEmpty(errors[0])) {
        return {};
    }
    return validateError(errors[0]);
}

export const ErrorListings = {
    message: {
        USER_ALREADY_CONFIRMED: 'User cannot be confirmed. Current status is CONFIRMED',
        INCORRECT_PASSWORD: 'Incorrect username or password.',
        CHANGE_PASSWORD_ATTEMPT_EXCEEDED: 'Attempt limit exceeded, please try after some time.',
    },

    forgotPasswordUserNotFound: (err) => {
        if (err.message === ErrorAPIMessages.UNVERIFIED_ACCOUNT) {
            return {
                key: uuid(),
                errCode: 'UNVERIFIED ACCOUNT',
                title: 'Account unverified',
                message: 'The email associated with this account is not yet verified. Please proceed to verify your account.',
            };
        }
        const { supportContact } = whiteLabelSettings();
        return {
            key: uuid(),
            errCode: 'COGNITO_USER_NOT_FOUND',
            title: 'User not found',
            message: `No registered account exists for this email. Please contact ECAL Support via email ${supportContact} to verify your account, or sign up to a new account at www.ecal.com.`,
        };
    },

    userAlreadyConfirmed: () => ({
        key: uuid(),
        errCode: 'COGNITO_USER_CONFIRMED',
        title: 'Account is verified',
        message: 'This email is already verified. Please proceed to the login page to access your account, or to reset your password.',
    }),

    incorrectPasswordInfo: () => ({
        key: uuid(),
        errCode: 'INCORRECT_PASSWORD_INFO',
        title: 'Change password failed',
        message: 'Failed to update password, incorrect password.',
    }),

    changePassLimitExceeded: () => {
        return {
            key: uuid(),
            errCode: 'CHANGE_PASSWORD_ATTEMPT_EXCEEDED',
            title: 'Attempt limit exceeded.',
            message: 'Change password limit exceed, please try again after sometime.',
        };
    },

    loginFailed: (err) => {
        const { supportContact } = whiteLabelSettings();
        let msg = err.message;
        let title = 'Sign in failed';
        if (isEmpty(err.message)) {
            msg = 'Falied to login.';
        }

        if (err.message === 'UserMigration failed with error no records found.') {
            title = 'No record found';
            msg = `This user record does not exist. Please contact Support (${supportContact}) to help verify your account and reset your login credentials.`;
        }

        if (err.message === 'User is not confirmed.') {
            title = 'User not confirmed';
            msg = 'This email has not been confirmed. Please check your inbox for instructions on how to verify your registered email.';
        }

        return {
            key: uuid(),
            errCode: 'SIGN_IN_FAILED',
            title,
            message: msg,
        };
    },

    resendCode: (err) => {
        if (err) {
            return {
                errKey: uuid(),
                errCode: 'COGNITO_USER_CONFIRMED',
                title: 'Account is verified',
                message: 'This email is already verified. Please proceed to the login page to access your account, or to reset your password.',
            };
        }

        return {
            errKey: uuid(),
            errCode: 'RESEND_VERIFICATION_CODE',
            title: 'Verfication code sent.',
            message: 'To complete your ECAL registration, A verification code is sent to your email. ',
        };
    },

    genericAuthError: (err) => {
        const { supportContact } = whiteLabelSettings();
        return {
            key: uuid(),
            errCode: 'AUTHENTICATION FAILED',
            title: 'Oops! Authentication failed.',
            message: err.message || `Please contact ${supportContact} to verify your existing account, or sign up to a new account at www.ecal.com.`,
        };
    },

};

export function serielizeUnhandledErrors (err) {
    return {
        errKey: uuid(),
        errCode: 'UNHANDLED_GENERIC_ERROR',
        title: 'Oops!',
        message: err.message || 'Something went wrong. Our technical team are looking into the problem. Please try again later.',
    };
}

export const ErrorActionSkipList = {
    getPublisherLogo: true,
    clicks: true,
    reminderImpressions: true,
    eventImpressions: true,
    monthlyActives: true,
    optinCount: true,
    liveEventCount: true,
    liveScheduleCount: true,
    totalSubscribers: true,
    analyticsDeviceTypes: true,
    analyticsPopularLocations: true,
    subscriptionsMap: true,
    analyticsClicks: true,
    analyticsSubscriptions: true,
    analyticsCalendarTypes: true,
    /* super admin */
    superAdminUpdateEmail: true,
    createPublisherUser: true,
    getPublisherUsers: true,
    deletePublisherUsers: true,
    activatePublisherUsers: true,
    updatePublisherUsers: true,
    createSubscriptionAWSMarketplace: true,
};
