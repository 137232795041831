import { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import {
    useSchedulesStore,
    useEnvStore,
} from '../../../store/models/index';

import { AssetType } from '../../../utils/constants';
import useImageUpload from '../../hooks/useImageUploadDynamicStore';
import useImageRenderable from '../../hooks/useImageRenderable';
import { DEFAULT_IMAGE } from '../../../commons/imageUploader/DefaultImage';

import useSchedulesErrors from '../../../store/models/schedule/errors/useSchedulesErrors';
import { fixLogoPath, logInfo } from '../../../utils';
import GenreList from '../../../commons/util/GenreList.json';
import { getSubGenreList } from '../manualEntry/GenreUtil';

function useScheduleInput (props) {
    const {
        defaultInput = {
        },
        mode,
        dataLoaded,
        setIsDirty,
    } = props;

    const schedStore = useSchedulesStore();
    const envStore = useEnvStore();

    const [input, setInput] = useState(defaultInput);

    /*
    function setInput (newState) {
        // use this to listent to the state changes...
        console.log('');
        console.log('');
        console.log('');
        console.log('useScheduleInput.setInput() ', newState);
        setInputState(newState);
    }
    */

    const [title, setTitle] = useState(mode === 'edit' ? 'Edit Schedule' : 'Add Schedule');
    const [imageError, setImageError] = useState('');
    const [saveDisabled, setSaveDisabled] = useState(false);

    const [isUploading, updateIsUploading] = useState(false);
    const [subGenres, setSubGenres] = useState([]);
    const [showSubGenres, setShowSubGenres] = useState(false);

    const [categoryLoadingMessage, setCategoryLoadingMessage] = useState('Loading...');

    function getSubGenreListLength (subGenreListValue) {
        if (isEmpty(subGenreListValue)) {
            return false;
        }
        return true;
    }

    function setIsUploading (v) {
        updateIsUploading(v);
    }

    function setScheduleLogo (url) {
        setInput({
            ...input,
            logo: url,
        });

        setIsUploading(false);
    }

    const {
        image: logoImage,
    } = useImageRenderable({
        imageSource: input.logo,
        customDefaultImage: DEFAULT_IMAGE,
    });

    const {
        image: logo,
        upload: uploadCalendarLogo,
        deleteImage: deleteLogo,
        imageUploadError: logoUploadError,
        imageError: logoImageError,
    } = useImageUpload({
        assetType: AssetType.CALENDAR_LOGO,
        observableDataSource: schedStore.observed.assets.scheduleLogo,
        consumableDataSource: schedStore.assets.scheduleLogo,
        setStateFunc: setScheduleLogo,
        customDefaultImage: logoImage,
        store: schedStore,
        uploadMethod: 'uploadScheduleLogo',
        shouldCheckFilesize: true,
    });

    function uploadImage (data) {
        setIsUploading(true);
        setSaveDisabled(true);
        uploadCalendarLogo(data);
    }

    const {
        errors,
        hasError,
    } = useSchedulesErrors({
        input,
    });

    useEffect(() => {
        logInfo('enableSave() from useEffect() hasError');
        enableSave();
    }, [hasError]);

    useEffect(() => {
        setIsUploading(false);
        if (errors.imageError) {
            logoUploadError();
            setImageError(errors.imageError);
        } else {
            setIsUploading(false);
            setImageError('');
        }
    }, [errors.imageError]);

    function updateDataSets () {
        if (mode === 'edit') {
            setTitle('Edit Schedule');
            schedStore.loadEditTarget();
            schedStore.mode = 'edit';
            console.log('EDIT MODE schedule: ', schedStore.scheduleEditTarget);
            if (schedStore.scheduleEditTarget) {
                const data = schedStore.scheduleEditTarget.selectedData;
                setInput({
                    ...input,
                    name: data.name,
                    reference: data.reference,
                    logo: fixLogoPath(data.logoUrl),
                    type: data.type,
                    banner: data.banner,
                    genre: data.genre,
                    subGenre: data.subGenre,
                });

                setImageError('');
                schedStore.treeCheck(data.categoryIds);
            }
        } else {
            schedStore.mode = 'add';
            setTitle('Add Schedule');
            setInput({
                type: 'fixture',
                name: '',
                reference: '',
                logo: '',
                banner: '',
                genre: '',
                subGenre: '',
            });
        }
    }
    useEffect(() => {
        schedStore.resetState();
        updateDataSets();
    }, []);

    useEffect(() => {
        updateDataSets();
    }, [mode]);

    useEffect(() => {
        updateDataSets();
    }, [dataLoaded]);

    useEffect(() => {
        setCategoryLoadingMessage(envStore.loadCategoryMessage);
    }, [envStore.observed.loadCategoryMessage]);

    function saveSchedule () {
        setIsDirty(false);
        if (mode === 'add') {
            schedStore.addSchedule(input);
        } else if (mode === 'edit') {
            schedStore.editSchedule(input);
        }
    }

    function enableSave () {
        /*
        logInfo('enableSave()');

        logInfo('input -> ', input);
        logInfo('isUploading ', isUploading);
        logInfo('hasError ', hasError);
        */

        if (isUploading) {
            setSaveDisabled(true);
            return;
        }

        if (hasError) {
            setSaveDisabled(true);
            return;
        }

        if (isEmpty(input.name)) {
            setSaveDisabled(true);
            return;
        }

        setSaveDisabled(false);
    }

    function updateInput (data) {
        setInput({
            ...input,
            ...data,
        });
    }

    useEffect(() => {
        enableSave();
    }, [input]);

    useEffect(() => {
        enableSave();
    }, [isUploading]);

    useEffect(() => {
        const subGenreList = getSubGenreList(input.genre);
        setSubGenres(subGenreList);
        const showSubGenresSelect = getSubGenreListLength(subGenreList);
        if (!showSubGenresSelect) {
            setInput({
                ...input,
                subGenre: '',
            });
        }
        setShowSubGenres(showSubGenresSelect);
    }, [input.genre]);

    return {
        input,
        setInput,
        errors,
        hasError,
        saveSchedule,
        title,
        imageError,
        uploadImage,
        logo,
        isCalendarLogoUploading: isUploading,
        deleteLogo,
        logoImageError,
        saveDisabled,
        updateInput,

        genres: GenreList,
        subGenres,
        showSubGenres,
        categoryLoadingMessage,
    };
}

export default useScheduleInput;
