// import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((p) => ({

    multilineInput: {
        fontSize: '16px',
        /*
        '& .MuiInputBase-input': {
            fontSize: '14px',
        },
        */
    },

    componentBase: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        flexWrap: 'wrap',
        marginTop: '-20px',
    },

    root: {
        display: 'flex',
        position: 'relative',
        border: '1px solid #B2B2B2',
        borderRadius: '4px',
        padding: '4px',
        overflow: 'hidden',
        minHeight: '56px',
        justifyContent: 'space-between',
        alignItems: 'center',
    },

    legend: {
        zIndex: 1,
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        whiteSpace: 'nowrap',
        top: '8px',
        left: '8px',
        fontSize: '12px',
        backgroundColor: '#FFFFFF',
        width: '20%',
    },

    helperText: {
        fontSize: '12px',
        color: 'rgba(0,0,0,0.54)',
        marginLeft: '8px',
        marginTop: '4px',
    },

    base: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        padding: '4px',
    },

    inSpan: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',

        whiteSpace: 'nowrap',

        marginRight: '4px',
        marginBottom: '4px',
        backgroundColor: '#F1F1f1',
        padding: '4px',
        borderRadius: '4px',
        textAlign: 'center',
        height: '24px',
    },

    truncate: {
        maxWidth: '200px',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        fontSize: '16px',
    },

    spanIcon: {
        marginLeft: '4px',
        cursor: 'pointer',
    },

    expandIcon: {
        marginRight: '8px',
        cursor: 'pointer',
    },

    editable: (props) => {
        return {
            whiteSpace: 'nowrap',
            fontSize: '16px',
            outline: '0px solid transparent',
            float: 'left',

            '&:empty::before': {
                content: '"' + props.placeHolder + '"',
                color: '#A1A1A1',
                fontSize: '14px',
            },
        };
    },

    /* Popmenu */

    popBox: {
        display: 'flex',
        backgroundColor: '#FFFFFF',
        borderRadius: '8px',
    },

    popPaper: {
        border: '1px solid rgba(0,0,0,0.30)',
    },

    menuRoot: {
        display: 'flex',
        overflow: 'scroll',
        maxHeight: '240px',
        backgroundColor: '#FFFFFF',
        borderRadius: '8px',
    },

    menuList: {
        width: '100%',
        backgroundColor: 'rgba(0,0,0,0)',
        borderRadius: '8px',
        fontSize: '16px',
    },

    noData: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '14px',
        color: '#737373',
    },

    tooltipStyle: ({ width, DEFAULT_WIDTH }) => {
        const style = {
            maxWidth: width || DEFAULT_WIDTH,
        };

        return style;
    },

}));

export default useStyles;
