/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { v4 as uuid } from 'uuid';
import {
    Grid,
    Box,
    Checkbox,
} from '@material-ui/core';
import TableSkeleton from './TableSkeleton';
import Widget from '../../components/Widget';
import { Typography } from '../../components/Wrappers';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import { moduleNoDataText } from '../util/utilities';

import EnhancedTableHead from './TableHeadings';
import useStyles from './table.styles';

function descendingComparator (a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator (order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort (array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function TableComponent (props) {
    const {
        tableTitle,
        moduleType,
        showProgress,
        tableData,
        columns,
        customCells,
        classes: parentClasses,
        options,
        rowsPerPage: rowNum,
        isInsideWidget,
        className,
        hasCheck,
        onSelectRow,
        sortableColumns,
        widgetClassName,
        removeEmptyRows,
        onRowClick,
        rowsClickEnabled = false,
        matchWidgetHeadingColor,
    } = props;

    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('calories');
    const [selected, setSelected] = useState([]);

    // eslint-disable-next-line no-unused-vars
    const [page, setPage] = React.useState(0);
  // eslint-disable-next-line no-unused-vars
    const [dense, setDense] = React.useState(false);
    // eslint-disable-next-line no-unused-vars
    const [rowsPerPage, setRowsPerPage] = React.useState(rowNum);

    const [rows, setRows] = React.useState([]);
    const [renderableRows, setRenderableRows] = useState([]);

    function computeRenderableRows () {
        console.log('computeRenderableRows() Compute Renderable Rows -> ');
        const sortedRows = stableSort(rows, getComparator(order, orderBy));
        const renderables = sortedRows.slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
        );
        setRenderableRows(renderables);
    }

    useEffect(() => {
      const rows = tableData;
        if (tableData && tableData.length >= 0) {
            if (rowNum <= 0) {
                setRowsPerPage(rows.length);
            } else {
                setRowsPerPage(rowNum);
            }
            setRows(rows);
        }
    }, [tableData]);

    useEffect(() => {
        computeRenderableRows(rows);
    }, [rows]);

    useEffect(() => {
        if (typeof onSelectRow === 'function') {
            onSelectRow(selected);
        }
    }, [selected]);

    /*
    // don't delete yet will be use to determine which properties are changing for rerendering issues
    useEffect(() => { console.log('memoizedProps had Changed -> ', props); }, [props]);
    useEffect(() => { console.log('hasCheck changed'); }, [hasCheck]);
    useEffect(() => { console.log('classes changed'); }, [parentClasses]);
    useEffect(() => { console.log('className changed'); }, [className]);
    useEffect(() => { console.log('tableData changed'); }, [tableData]);
    useEffect(() => { console.log('columns changed'); }, [columns]);
    useEffect(() => { console.log('customCells changed'); }, [customCells]);
    useEffect(() => { console.log('tableTitle changed'); }, [tableTitle]);
    useEffect(() => { console.log('moduleType changed'); }, [moduleType]);
    useEffect(() => { console.log('showProgress changed'); }, [showProgress]);
    useEffect(() => { console.log('options changed'); }, [options]);
    useEffect(() => { console.log('isInsideWidget changed'); }, [isInsideWidget]);
    useEffect(() => { console.log('rowsPerPage changed'); }, [rowsPerPage]);

    useEffect(() => { console.log('hasRowSelection changed'); }, [props.hasRowSelection]);
    useEffect(() => { console.log('matchWidgetHeadingColor changed'); }, [props.matchWidgetHeadingColor]);
    useEffect(() => { console.log('removeEmptyRows changed'); }, [props.removeEmptyRows]);
    useEffect(() => { console.log('rowsClickEnabled changed'); }, [props.rowsClickEnabled]);
    useEffect(() => { console.log('sortableColumns changed'); }, [props.sortableColumns]);

    useEffect(() => { console.log('onShowQRCode changed'); }, [props.onShowQRCode]);
    useEffect(() => { console.log('onSelectRow changed'); }, [props.onSelectRow]);
    useEffect(() => { console.log('onDelete changed'); }, [props.onDelete]);
    useEffect(() => { console.log('onShowButtonCode changed'); }, [props.onShowButtonCode]);
    */

    const classes = useStyles();

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = event => {
        if (event.target.checked) {
            const newSelecteds = rows.map(n => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name, rowData) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }

        setSelected(newSelected);
        if (typeof onRowClick === 'function') {
            onRowClick(rowData);
        }
    };

    function isSelected (name) {
        return selected.indexOf(name) !== -1;
    }

    const emptyRows = rows && rows.length > 0 ? rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage) : 4;

    function renderCell ({ index, column, row, rowIndex }) {
        const field = column.id;
        let cellValue = row[field];
        const labelFunc = column.cellLabelFunc;

        if (customCells && customCells[field]) {
            if (typeof customCells[field] === 'function') {
                return (
                    <TableCell
                        key={'cell_' + index + '_' + uuid()}
                        align="left"
                        className={column.cellClassName ? parentClasses[column.cellClassName] : ''}
                        >
                            {customCells[field]({ index, column, row, props, rowIndex })}
                    </TableCell>
                );
            }
        }

        if (column.customCell && typeof column.customCell === 'function') {
            return (
                <TableCell
                    key={'cell_' + index + '_' + uuid()}
                    align="left"
                    className={column.cellClassName ? parentClasses[column.cellClassName] : ''}
                    >
                        {column.customCell({ index, column, row, props, rowIndex })}
                </TableCell>
            );
        }

        try {
            cellValue = decodeURIComponent(cellValue);
            cellValue = cellValue === 'undefined' ? '' : cellValue;
        } catch (err) {
            console.log(`TableContainer.renderCell() Error Decoding '${field}' with value '${cellValue}'`);
        }

        return (
            <TableCell
                key={'cell_' + index + '_' + uuid()}
                align="left"
                className={column.cellClassName ? parentClasses[column.cellClassName] : ''}
                >

                <Typography
                    variant={'body2'}
                >
                    {typeof labelFunc === 'function' ? labelFunc({ row, props }) : cellValue}
                </Typography>
            </TableCell>
        );
    }

    function renderCells ({ row, isItemSelected, labelId, rowIndex }) {
        if (!columns) return [];

        let cells = columns.map((column, index) => {
            return renderCell({
                index,
                column,
                row,
                rowIndex,
            });
        });

        if (hasCheck) {
            cells = [
                renderCheckboxCell({ isItemSelected, labelId, row }),
              ...cells,
            ];
        }

        return cells;
    }

    function renderCheckboxCell ({ isItemSelected, labelId, row }) {
        return (
            <TableCell padding="checkbox">
                <Checkbox
                    color="primary"
                    checked={isItemSelected}
                    onClick={event =>
                        handleClick(event, row.id, row)
                    }
                    inputProps={{
                        'aria-labelledby': labelId,
                    }}
                />
            </TableCell>
        );
    }

    function customRowClass ({ row, rowIndex }) {
        if (typeof options.rowCustomClass === 'function') {
            return options.rowCustomClass({ row, props, rowIndex });
        }
        return undefined;
    }

    function renderTableBody () {
        return (
            <React.Fragment>
                   <EnhancedTableHead
                                numSelected={selected.length}
                                rowCount={rows.length}

                                headCells={columns}
                                order={order}
                                orderBy={orderBy}
                                onSelectAllClick={handleSelectAllClick}
                                onRequestSort={handleRequestSort}

                                hasCheck={hasCheck}
                                hasSort={sortableColumns}
                            />
                <TableBody>
                    {renderableRows.map((row, index) => {
                            const isItemSelected = hasCheck ? isSelected(row.id) : hasCheck;
                            const labelId = `enhanced-table-checkbox-${index}`;
                            return (
                                <TableRow
                                    hover
                                    onClick={event => {
                                        if (rowsClickEnabled) {
                                            handleClick(event, row.id, row);
                                        }
                                    }}
                                    // role="checkbox"
                                    aria-checked={isItemSelected}
                                    tabIndex={-1}
                                    key={row.id}
                                    selected={isItemSelected}
                                    className= {classNames([
                                        classes.tableRow,
                                        customRowClass({ row, rowIndex: index }),
                                    ])}
                                >
                                    {renderCells({ row, isItemSelected, labelId, rowIndex: index })}
                                </TableRow>
                            );
                        })}
                    {!removeEmptyRows && emptyRows > 0 && (
                        <TableRow
                            style={{
                                height: (dense ? 33 : 53) * emptyRows,
                            }}
                        >
                            <TableCell colSpan={6} />
                        </TableRow>
                    )}
                </TableBody>
            </React.Fragment>
        );
    }

    function renderTable () {
        if (!showProgress && (!tableData || tableData.length <= 0)) {
            return (
                <Grid
                    className={classes.noData}
                >
                    <Typography variant={'body2'}>
                        {moduleNoDataText(moduleType)}
                    </Typography>
                </Grid>
            );
        }

        return (
            <TableContainer>
                <Table
                    aria-labelledby="tableTitle"
                    size={dense ? 'small' : 'medium'}
                    aria-label="enhanced table"
                >
                    { showProgress ? <TableSkeleton /> : renderTableBody() }
                </Table>
            </TableContainer>
        );
    }

    return (
        <React.Fragment>
            {isInsideWidget && (
                    <Widget
                        className={widgetClassName}
                        inheritHeight disableWidgetMenu>
                        <Box
                            justifyContent={'space-between'}
                            display={'flex'}
                            alignItems={'flex-start'}
                            marginBottom={'16px'}
                            // marginTop={'32px'}
                        >
                            <Box
                              display={'flex'}
                              alignItems={'flex-start'}
                            >
                                {/* {options.titleIcon ? options.titleIcon() : ''} */}
                                {matchWidgetHeadingColor
? (
                                  <Typography variant={'h5'} color="text" colorBrightness={'secondary'}>
                                    {tableTitle}
                                  </Typography>
                                )
 : (
                                  <Typography variant={'h5'}>
                                    {tableTitle}
                                  </Typography>
                                )}
                            </Box>
                        </Box>
                        { renderTable() }
                    </Widget>
            )}

            {!isInsideWidget && (
                <Grid className={className}>
                    { renderTable() }
                </Grid>
            )}
        </React.Fragment>
    );
};

TableComponent.propTypes = {
    showProgress: PropTypes.func,
    onEdit: PropTypes.func,
    onDelete: PropTypes.func,
    onSelectRow: PropTypes.func,
    options: PropTypes.object,
    rowsPerPage: PropTypes.number,
    insideWidget: PropTypes.bool,
    hasRowSelection: PropTypes.bool,
    hasCheck: PropTypes.bool,
    sortableColumns: PropTypes.bool,
    removeEmptyRows: PropTypes.bool,
    rowsClickEnabled: PropTypes.bool,
    matchWidgetHeadingColor: PropTypes.bool,
    moduleType: PropTypes.string,
};

TableComponent.defaultProps = {
    showProgress: false,
    onEdit: () => {},
    onDelete: () => {},
    onSelectRow: () => {},
    options: {},
    rowsPerPage: 10,
    isInsideWidget: true,
    hasRowSelection: true,
    hasCheck: false,
    sortableColumns: true,
    removeEmptyRows: false,
    rowsClickEnabled: false,
    matchWidgetHeadingColor: false,
    moduleType: 'data',
};

export default React.memo(TableComponent);
