import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Grid,
    Box,
    Tooltip,
    Button,
} from '@material-ui/core';
// import Input from '@material-ui/core/TextField';
import TextField from '@material-ui/core/TextField';
import LinkIcon from '@material-ui/icons/Link';
import {
    Typography,
    // Button,
} from '../../../components/Wrappers/Wrappers';
import ButtonProgress from '../../../commons/ButtonProgress';

// import Widget from '../../../components/Widget';
import { useStyles } from '../profile.styles';

import useUserInputDialog from './useUserInputDialog';

import List from './list';
import { usePublishersStore } from '../../../store/models';
function AccountSettings (props) {
    // const classes = useStyles();
    const {
        publisher,
        onDataChange,
        onSave,
        inProgress,
        publisherUsers,
    } = props;

    const [data, setData] = useState(publisher);
    const [saveDisabled, setSaveDisabled] = useState(true);
    const publishersStore = usePublishersStore();
    const classes = useStyles();

    const {
        openDialog,
        renderUserInputDialog,
    } = useUserInputDialog();

    useEffect(() => {
        setData(publisher);
    }, [publisher]);

    useEffect(() => {
        if (typeof onDataChange === 'function') {
            onDataChange(data);
        }
    }, [data]);

    function handleChange (e) {
        setData({
            ...data,
            email: e.target.value,
        });
        setSaveDisabled(false);
    }

    function updateUserStatus (data) {
        if (data.status === 'active') {
            publishersStore.deletePublisherUsers({
                publisher: publishersStore.activePublisher,
                publisherUser: data,
            });
        } else {
            publishersStore.activatePublisherUsers({
                publisher: publishersStore.activePublisher,
                publisherUser: data,
            });
        }
    }

    function renderLinkNewUserButton () {
        return (
          <Tooltip title="Link a New User">
            <Button
              variant="contained"
              onClick= { () => {
                openDialog();
              }}
              size="small"
              color="primary"
              style={{ marginBottom: 33 }}>
                <span className={classes.superAdminText}> Add User </span>
                    <LinkIcon
                        fontSize="small"
                        style={{ marginLeft: 3 }}
                    />
            </Button>
          </Tooltip>
        );
      }

    return (
        <React.Fragment>
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <div>
                    <Grid item justify={'center'} container>
                        <Box
                            display={'flex'}
                            flexDirection={'column'}
                            width={600}
                            className={classes.boxStyling}
                        >
                            <Grid className={classes.listHeader}>
                                <Typography
                                    variant={'h5'}
                                    weight={'medium'}
                                    style={{ marginBottom: 30 }}
                                >
                                    Account Settings
                                </Typography>
                                    { renderLinkNewUserButton() }
                            </Grid>
                            <TextField
                                id="email"
                                label="Email"
                                onChange={handleChange}
                                name="email"
                                value={data.email || ''}
                                variant="outlined"
                                style={{ marginBottom: 35 }}
                                helperText="Please enter your email"
                            />
                            <Box
                                display={'flex'}
                                justifyContent={'flex-end'}
                            >
                                <ButtonProgress
                                    disabled={saveDisabled}
                                    showProgress={inProgress}
                                    label="Save"
                                    onClick={(e) => {
                                        onSave(data);
                                    }}
                                />
                            </Box>
                        </Box>
                    </Grid>
                </div>
            </Grid>
        </Grid>

        { publisherUsers.length > 0 && <Grid
            container
            spacing={3}
        >
            <Grid item xs={12}>
                <div>
                    <Grid item justify={'center'} container>
                        <Box
                            display={'flex'}
                            flexDirection={'column'}
                            width={600}
                            className={classes.boxStyling}
                        >
                            <Typography
                                variant={'h5'}
                                weight={'medium'}
                                style={{ marginBottom: 10 }}
                            >
                                User Group
                            </Typography>

                            <List
                                listData={publisherUsers}
                                // showProgress={labelStore.observed.labelsLoading}
                                onEdit={(data) => {
                                    openDialog(data, 'edit');
                                }}

                                onDelete={(data) => {
                                    updateUserStatus(data);
                                }}
                            />
                        </Box>
                    </Grid>
                </div>
            </Grid>
        </Grid> }
        { renderUserInputDialog() }
        </React.Fragment>
    );
}

AccountSettings.propTypes = {
    publisher: PropTypes.object,
    onDataChange: PropTypes.func,
    inProgress: PropTypes.bool,
    publisherUsers: PropTypes.any,
};

AccountSettings.defaultProps = {
    publisher: {
        firstName: '',
        email: '',
        role: 'user',
    },
    onDataChange: () => { },
    onSave: () => {},
    inProgress: false,
    publisherUsers: [],
};

export default AccountSettings;
