import { v4 as uuid } from 'uuid';
import { isEmpty } from 'lodash';
import { logInfo } from '../../../utils';
import {
    apiGetButtons,
    apiAddWidget,
    apiEditWidgets,
    apiDeleteWidgets,
    apiGetSubscribersByWidget,
    apiGetSubscribersByEventDisplay,
} from './api';

export async function getButtons (type = 'button') {
    this.env.errorStore.action = 'getButtons';
    console.log('');
    console.log('getButtons():');
    const publisher = this.env.userStore.publisher;
    const res = await apiGetButtons({
        type: type,
        token: publisher.token,
        traceId: this.env.traceId,
        page: this.pageInfo.page + 1,
        pageLimit: this.pageInfo.limit,
    });
    let respButtonSubscribers = [];
    let respEventSubscribers = [];
    if (type !== 'event') {
        respButtonSubscribers = await apiGetSubscribersByWidget({
            token: publisher.token,
            traceId: this.env.traceId,
        });
    } else {
        respEventSubscribers = await apiGetSubscribersByEventDisplay({
            token: publisher.token,
            traceId: this.env.traceId,
        });
    }
    console.log('Buttons List: ', res, 'resSubscribers', respButtonSubscribers);
    if (res.data && res.data.data) {
        if (respButtonSubscribers.data && respButtonSubscribers.data.data) {
            this.buttons = processList(res.data.data.getWidgets.edges, respButtonSubscribers.data.data.subscribersByWidget);
        }
        if (respEventSubscribers.data && respEventSubscribers.data.data) {
            this.buttons = processList(res.data.data.getWidgets.edges, respEventSubscribers.data.data.subscribersByEventDisplay);
        }
        this.pageInfo.count = res.data.data.getWidgets.totalCount;
        console.log('will update buttons ', this.observed.buttons);
        this.observed.buttons = uuid();
    } else {
        // handle error.
    }
}

function processList (list, subscribersByWidget) {
    const subscribersByWidgetObject = subscribersByWidget;
    return list.map((edge) => {
        const matchingItem = subscribersByWidgetObject?.find(item => item.id === edge.node.id);
        edge.node.subscribers = matchingItem?.count ? matchingItem.count : 0;
        return edge.node;
    });
}

export async function uploadWidgetAssets ({ file, assetType }) {
    this.env.errorStore.action = 'uploadWidgetAssets';
    const res = await this.env.userStore.uploadAssets({
        file,
        assetType,
    });
    console.log('uploadButtonAssets(): REsponse: ', res);
    this.assets[assetType] = res.data.data;
    this.observed.assets = {
        ...this.observed.assets,
        [assetType]: uuid(),
    };
}

export async function generateImage (widget, options = {}) {
    let opts = options;
    if (isEmpty(opts)) {
        opts = { textMatrix: 'matrix(1 0 0 1 58.5319 28.4999)' };
    }

    this.env.errorStore.action = 'generateButtonImage';
    const svgData = await this.env.userStore.generateSVG({
        width: widget.display.width,
        text: widget.display.text,
        buttonColour: widget.style.backgroundColour,
        fontColour: widget.style.fontColour,
        template: widget.style.template || 'ecal-round', // ecal-round, ecal-pill, ecal-square, ecal-icon
        theme: widget.style.theme || 'purple',
        textMatrix: opts.textMatrix || 'matrix(1 0 0 1 58.5319 28.4999)',
    });

    return svgData.data.url;
}

export async function addWidget (widget, options) {
    logInfo('adding button: ', widget);
    this.env.errorStore.action = 'addWidget';
    const publisher = this.env.userStore.publisher;
    this.observed.buttonsLoading = true;

    if (widget.type === 'button') {
        widget.image = await this.generateImage(widget, options);
    }

    const res = await apiAddWidget({
        token: publisher.token,
        traceId: this.env.traceId,
        widget: {
            ...widget,
            display: sanitizeWidgetDisplay(widget.display),
            subscriberData: sanitizeSubscriberData(widget.subscriberData),
            settings: sanitizeWidgetSettings(widget, publisher),
        },
    });

    logInfo('Add Widget () ', res);

    let event = 'Created button display';
    if (widget.type === 'ad') {
        event = 'Created ad display';
    } else if (widget.type === 'event') {
        event = 'Created event display';
    }

    const metadata = {
        display_name: widget.name,
        type: widget.type,
        ip: this.env.userStore.IP || '',
    };

    logInfo('Created display', metadata);
    window.Intercom(
        'trackEvent',
        event,
        metadata);

    this.env.history.push('/admin/displays/' + widget.type);
}

function sanitizeSubscriberData (subscriberData) {
    console.log('');
    console.log('');
    console.log('');
    console.log('sanitizeSubscriberData(): ', subscriberData);

    if (!subscriberData || isEmpty(subscriberData)) {
        return {};
    }

    const registrationFields = {
        ...subscriberData.registrationFields,
        requiredAgeValue: subscriberData.registrationFields.requiredAgeValue === '' ? 0 : subscriberData.registrationFields.requiredAgeValue,
        parentConsentAgeValue: subscriberData.registrationFields.parentConsentAgeValue === '' ? 0 : subscriberData.registrationFields.parentConsentAgeValue,
    };

    console.log('Registration Field to Send: ', registrationFields);
    const customFields = subscriberData.customFields.map((item) => {
        return {
            ...item,
            modified: undefined,
        };
    });

    return {
        registrationFields,
        customFields,
    };
}

function sanitizeWidgetData (widgets, publisher) {
    return widgets.map(item => {
        return {
            ...item,
            display: sanitizeWidgetDisplay(item.display),
            subscriberData: sanitizeSubscriberData(item.subscriberData),
            settings: sanitizeWidgetSettings(item, publisher),
            created: undefined,
            modified: undefined,
            template: undefined,
            subscribers: undefined,
        };
    });
}

export async function editWidget (widget, options) {
    // widget.display?.categories?.push('Uncategorized');
    console.log(' ', widget);
    console.log('Edit Widget: ', widget);
    if (widget.type === 'button') {
        const image = await this.generateImage(widget, options);
        widget.image = image;
    }
    this.editWidgets([widget], true, widget.type);
}

export async function editWidgets (widgets, navigateToList = true, type = 'button') {
    this.env.errorStore.action = 'editWidgets'; // use the query as the action to retrieve error from query
    console.log('');
    console.log('');
    console.log('editWidgets(): ', widgets);
    this.observed.buttonsLoading = true;
    const publisher = this.env.userStore.publisher;
    const res = await apiEditWidgets({
        token: publisher.token,
        traceId: this.env.traceId,
        widgets: sanitizeWidgetData(widgets, publisher),
    });

    console.log('Button Response: ', res);
    if (navigateToList) {
        if (type) {
            this.env.history.push('/admin/displays/' + type);
        } else {
            this.env.history.push('/admin/displays');
        }
    } else {
        this.getButtons(type);
    }
}

export async function makeWidgetsDraft (widgets, type = 'button') {
    this.observed.buttonsLoading = true;
    const drafted = widgets.map((item) => {
        console.log('creating draft: ', item);
        return {
            id: item,
            draft: true,
            type,
        };
    });
    this.editWidgets(drafted, false, type);
}

export async function makeWidgetsLive (widgets, type = 'button') {
    this.observed.buttonsLoading = true;
    const live = widgets.map((item) => {
        return {
            id: item,
            draft: false,
            type,
        };
    });
    this.editWidgets(live, false, type);
}

export async function deleteWidgets (widgets, type = 'button') {
    this.env.errorStore.action = 'deleteWidgets';
    console.log('deleteWidgets()');
    this.observed.buttonsLoading = true;
    const publisher = this.env.userStore.publisher;
    await apiDeleteWidgets({
        token: publisher.token,
        traceId: this.env.traceId,
        widgets,
        type,
    });
    this.getButtons(type);
}

export function updateSchedulesFilterForButton (res) {
    console.log('');
    console.log('');
    console.log('updateSchedulesFilterForButton()', res.data.data.getSchedules);
    if (res.data &&
        res.data.data &&
        res.data.data.getSchedules) {
        const edges = res.data.data.getSchedules.edges;
        this.buttonSchedules = edges.map(item => ({
            value: item.node.id,
            label: item.node.name,
            catName: item.node.categoryNames,
        }));

        console.log('Schedules filter: ', this.buttonSchedules);
        this.observed.buttonSchedules = uuid();
    }
}

function sanitizeWidgetDisplay (display) {
    const newDisplay = { ...display };
    // remove Uncategorized.... sanitize categories from old data...
    if (newDisplay.categories) {
        newDisplay.categories = newDisplay.categories.filter(c => (c !== 'Uncategorized'));
    }
    return newDisplay;
}

function replacePublisherName (msg, publisher) {
    if (!msg) {
        return '';
    }
    let pubName = publisher.company || publisher.name;
    if (isEmpty(pubName)) {
        pubName = publisher.orgName;
    }
    const newMesssage = msg.replace('[Publisher name]', pubName);
    return newMesssage;
}

function sanitizeWidgetSettings (widget, publisher) {
    const oldSettings = widget.settings;
    const newSettings = { ...oldSettings };

    if (newSettings.sharing) {
        newSettings.sharing.message = replacePublisherName(newSettings.sharing.message, publisher);
    }

    if (newSettings.welcomeMessage) {
        newSettings.welcomeMessage.message = replacePublisherName(newSettings.welcomeMessage.message, publisher);
        newSettings.welcomeMessage.title = replacePublisherName(newSettings.welcomeMessage.title, publisher);
    }

    return newSettings;
}
