import { makeStyles } from '@material-ui/styles';
// import tinycolor from 'tinycolor2';

export default makeStyles(theme => ({

    boxStyling: {
        backgroundColor: '#ffffff',
        padding: 24,
        '& .rct-node-icon': {
        color: '#0c0644!important',
        },
    },

    mainContainer: {
        width: '100%',
        maxWidth: '600px',
        backgroundColor: '#ffffff',
        borderRadius: 4,
        padding: '24px',
        boxShadow: theme.customShadows.widget,
    },

    titleBase: {
        display: 'flex',
        width: '100%',
        justifyContent: 'flex-start',
        marginBottom: '24px',
    },

    sectionRoot: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        marginBottom: '40px',
    },

    sectionRootNoBottomPad: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },

    sectionTitle: {
        marginBottom: '8px',
    },

    sectionDescription: {
        marginBottom: '16px',
    },

    sectionContent: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
       // backgroundColor: '#FBFBFB',
        minHeight: '40px',
        borderRadius: '6px',
       // padding: '16px',
    },

    switchControl: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginBottom: '16px',
    },

    inputBox: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
    },

    imageSpace: {
        // display: 'flex',
        // justifyContent: 'center',
        // alignItems: 'center',
        marginBottom: '16px',
        '& .image-item': {
            marginBottom: '8px !important',
        },

        '& .MuiButtonBase-root': {
            marginRight: '0px !important',
        },

        '& .MuiBox-root': {
            justifyContent: 'center !important',
        },
    },

    imageTitle: {
        marginBottom: '10px !important',
        // fontWeight: 'bold !important',
    },

    imageMessage: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 30,
        alignItems: 'flex-start',
        justifyContent: 'center',
    },

    textInput: {
        width: '100%',
    },

    messageInput: {
        minHeight: '200px',
    },

    uploadSection: {
        // width: '170px',
        // height: '140px',
    },
}));
