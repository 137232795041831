import React from 'react';
import PropTypes from 'prop-types';
// import { cloneDeep } from 'lodash';
import moment from 'moment';
import {
    Grid,
    Box,
    Tooltip,
//    Menu,
//   MenuItem,
} from '@material-ui/core';
import {
  Button,
  Typography,
} from '../Wrappers/Wrappers';
import CheckboxTree from '../react-checkbox-tree-local/src';
import {
    CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
    MoreVert as MoreIcon,
    // ArrowForwardIos as ArrowRightIcon,
} from '@material-ui/icons';
import ArrowRightIcon from '@material-ui/icons/ChevronRight';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined';
import PlaylistAddOutlinedIcon from '@material-ui/icons/PlaylistAddOutlined';
import RefreshIcon from '@material-ui/icons/Refresh';
import '../react-checkbox-tree-local/src/react-checkbox-tree.css';

import Dialog from '../../commons/dialog/Dialog';
import TextField from '@material-ui/core/TextField';
import TreeSkeleton from './skeletonForTree';
import { moduleNoDataText } from '../../commons/util/utilities';
import { Patterns } from '../../utils/validations';

export default class Widget extends React.Component {
  static propTypes = {
    loadingIndicator: PropTypes.bool,
    filterText: PropTypes.string,
    onLoadCategories: PropTypes.func,
    lastDataLoad: PropTypes.string,
    setLastDataLoad: PropTypes.func,
  };

  static defaultProps = {
    loadingIndicator: false,
    filterText: '',
    onLoadCategories: () => {},
    lastDataLoad: '',
    setLastDataLoad: () => {},
  };

    state = {
        checked: [],
        expanded: [],
        selectedParent: '',
        newCategoryName: '',
        dialogFlag: false,
        isRoot: false,
        saveDisabled: true,
        categoryNameError: '',
    };

    closeModal = () => {
        // this.setState({ dialogFlag: false });
        const {
            onCloseAddDialog,
        } = this.props;
        if (typeof onCloseAddDialog === 'function') {
            onCloseAddDialog();
        }
    };

    getTreeNodeValue (el) {
        while (el.parentNode) {
            el = el.parentNode;
            if (el.tagName === 'li' || el.getAttribute('data-value')) {
                const v = el.getAttribute('data-value');
                return v;
            }
        }
        return null;
    }

    showDialogWithData (target) {
        /* render menu here */
        const {
            onOpenMenu,
        } = this.props;
        const data = this.getTreeNodeValue(target.parentNode);
        console.log('To render menu from here... ', data);

        if (typeof onOpenMenu === 'function') {
            onOpenMenu(target.parentNode, data);
        }

        this.setState({
            ...this.state,
            selectedParent: data,
            isRoot: false,
            /* dialogFlag: true, */
            newCategoryName: '',
        });
    }

    handleLoadCategories = (loadCategories, setLastDataLoad) => {
      setLastDataLoad(moment().calendar());
      loadCategories();
    }

    static getDerivedStateFromProps (nextProps, prevState) {
        if (nextProps.openAddDialog !== prevState.dialogFlag) {
          return ({ dialogFlag: nextProps.openAddDialog });
        }
        return null;
      }

    validateCategoryName = () => {
      console.log('validating category name...');
      const isValid = Patterns.categoryName.test(this.state.newCategoryName);
      console.log('Is valid? ', isValid);
      if (isValid) {
        this.setState({
          ...self.state,
          saveDisabled: false,
          categoryNameError: '',
        });
      } else {
        this.setState({
          ...self.state,
          saveDisabled: true,
          categoryNameError: 'Category name can only have alpha-numeric and some special characters like : - and _.',
        });
      }
    }

    handleCategoryNameChange = (e) => {
      const value = e.target.value;
      const self = this;

      this.setState({
        ...self.state,
        newCategoryName: value,
      });

      clearTimeout(window.categoryNameDebounce);
      window.categoryNameDebounce = setTimeout(this.validateCategoryName, 500);
    }

    render () {
        const {
            nodesFiltered,
            filterText,
            checked,
            expanded,
            loadingIndicator,
            onCheck,
            onExpand,
            onNodeClick,
            onAddChildNode,
            onFilterChange,
            onShowAddRootNode,
            showButton = true,
            onLoadCategories,
            lastDataLoad,
            setLastDataLoad,
            loadingMessage,
        } = this.props;

        return (
            <React.Fragment>
                <Grid container>
                    <Box
                        width={'100%'}
                        border={'1px solid lightgrey'}
                        padding={'20px'}
                        // display={'flex'}
                        // justifyContent={'space-between'}
                        borderRadius={'4px'}
                        // boxShadow={'0px 3px 11px 0px #e8eafc, 0 3px 3px -2px #b2b2b21a, 0 1px 8px 0 #9a9a9a1a'}
                    >
                        <Box
                            height={'36px'}
                            display={'flex'}
                            justifyContent={'space-between'}
                        >
                            <input
                                className='filter-text'
                                placeholder='Search categories'
                                type='text'
                                value={filterText}
                                style={{ width: '250px', padding: '16.5px 14px', fontSize: '16px' }}
                                onChange={onFilterChange}
                            />
                            {showButton
                            ? <Button
                                variant="contained"
                                color="primary"

                                onClick={() => {
                                    this.setState({
                                        ...this.state,
                                        isRoot: true,
                                        /* dialogFlag: true, */
                                        newCategoryName: '',
                                    });
                                    if (typeof onShowAddRootNode === 'function') {
                                        onShowAddRootNode();
                                    }
                                }}
                                    >
                                <PlaylistAddOutlinedIcon />&nbsp; Add category
                            </Button>
                            : null}

                        </Box>
                        <Box
                          display='flex'
                          marginTop='4px'
                        >
                          { loadingIndicator
                            ? <Typography
                                variant="subtitle2"
                                style={{ marginTop: '2px', fontSize: '12px' }}
                              >
                                { loadingMessage || 'Loading...' }
                              </Typography>
                            : (
                              <>
                                <Typography
                                  variant="subtitle2"
                                  style={{ marginTop: '2px', marginRight: '4px', fontSize: '12px' }}
                                >
                                  Last data refreshed: {lastDataLoad}
                                </Typography>
                                <Tooltip title="Click to refresh">
                                  <RefreshIcon
                                    fontSize="small"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => this.handleLoadCategories(onLoadCategories, setLastDataLoad)} />
                                </Tooltip>
                              </>
                          )}
                        </Box>
                        {
                          // eslint-disable-next-line multiline-ternary
                          !loadingIndicator && !nodesFiltered.length ? (
                            <Box
                              marginTop={'30px'}
                            >
                              {moduleNoDataText('categories')}
                            </Box>
                          ) : (
                            <Box
                              marginTop={'30px'}
                            >
                              { loadingIndicator

                              ? <TreeSkeleton />

                              : <CheckboxTree
                                  nodes={nodesFiltered}
                                  checked={checked}
                                  expanded={expanded}
                                  id={'categoryTree'}
                                  onCheck={checked => {
                                      // this.setState({ ...this.state, checked });
                                      onCheck(checked);
                                  }}
                                  onExpand={(expanded, targetNode) => {
                                      // this.setState({ ...this.state, expanded });
                                      onExpand(expanded, targetNode);
                                  }}
                                  onClick={(data) => {
                                      console.log('Click...', data);
                                      onNodeClick(data);
                                  }}
                                  expandOnClick={false}
                                  icons={{
                                      check: <CheckBoxOutlinedIcon fontSize="small" />,
                                      uncheck: <CheckBoxOutlineBlankIcon fontSize="small" />,
                                      halfCheck: <CheckBoxOutlinedIcon fontSize="small" />,
                                      expandClose: <ArrowRightIcon fontSize="medium" />,
                                      expandOpen: <ExpandMoreOutlinedIcon fontSize="medium" />,
                                      // expandAll: <span className="rct-icon rct-icon-expand-all" />,
                                      // collapseAll: <span className="rct-icon rct-icon-collapse-all" />,
                                      parentClose: <MoreIcon fontSize="medium"
                                          onClick={(e) => {
                                              e.stopPropagation();
                                              this.showDialogWithData(e.target);
                                          }}
                                      />,
                                      parentOpen: <MoreIcon fontSize="medium"
                                          onClick={(e) => {
                                              e.stopPropagation();
                                              this.showDialogWithData(e.target);
                                          }}
                                      />,
                                      leaf: <MoreIcon fontSize="medium"
                                      onClick={(e) => {
                                          e.stopPropagation();
                                              this.showDialogWithData(e.target);
                                          }}
                                      />,
                                  }}
                                />
                              }
                            </Box>
                          )
                        }
                    </Box>
                </Grid>

                <Dialog
                    openDialog={this.state.dialogFlag}
                    title={'Add category'}
                    buttonPrimary= 'Confirm'
                    buttonSecondary= 'Close'
                    primaryDisableFlag={this.state.saveDisabled}
                    closeModal={this.closeModal}

                    handleAgree={() => {
                        onAddChildNode({
                            name: this.state.newCategoryName,
                            parentId: this.state.isRoot ? '' : this.state.selectedParent,
                        });

                        this.setState({
                            ...this.state,
                            isRoot: false,
                        });
                    }}
                    dialogContextText={'Categories allow you to group common schedules together, making it easy for users to browse and find the schedules they want.'}
                    >
                        <TextField
                            id="category-add"
                            label="Name"
                            onChange={(e) => {
                              this.handleCategoryNameChange(e);
                            }}
                            name="category"
                            value={this.state.newCategoryName}
                            variant="outlined"
                            style={{ marginTop: 23, marginBottom: 35, width: '100%' }}
                            error={this.state.categoryNameError}
                            helperText={this.state.categoryNameError || 'Please enter category name'}
                        />
                </Dialog>

            </React.Fragment>
        );
    }
}
