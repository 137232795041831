import { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { isEmpty, isArray } from 'lodash';
import { useErrorStore } from './index';
import { GenericErrors, serielizeUnhandledErrors } from './error_map';

import { useTouchContext } from '../../../app/context/TouchContext';

function useErrors (props) {
    const {
        hook,
        shouldValidateInput = true,
        isGeneric = false,
        input,
        inputValidator,
        serviceErrorSerializer,
        errorObservables,
        useTouched = false,
    } = props;

    const store = useErrorStore();
    const [isInit, setIsInit] = useState(true);
    const [errors, setErrors] = useState({});
    const [hasError, setHasError] = useState(false);
    const [hasInputError, setHasInputError] = useState(false);
    const [isGenericError, setIsGenericError] = useState(false);

    const {
        touches,
        clearTouches,
    } = useTouchContext(
        hook,
        {
            touchEnabled: useTouched,
        }
    );

    useEffect(() => {
        if (useTouched) {
            clearTouches(hook);
        }
    }, []);

    useEffect(() => {
        if (!input) return;
        if (isGeneric) return;
        clearTimeout(window.validateInputErrorTimeout);
        function doValidation () {
            if (isInit) {
                // don't check things on init
                setIsInit(false);
                return;
            }

            if (!shouldValidateInput) {
                return;
            }

            let __touched = touches;
            if (!useTouched) {
                __touched = null;
            }

            const errs = inputValidator(input, __touched);

            if (!isEmpty(errs)) {
                setErrors(errs);
                setHasError(true);
                setHasInputError(true);
            } else {
                setErrors({});
                setHasError(false);
                setHasInputError(false);
            }
        }
        window.validateInputErrorTimeout = setTimeout(doValidation, 400);
    }, [input]);

    useEffect(() => {
        //* this will process/serilized with serviceErrorSerializer function
        if (!store.errors && !isGeneric) {
            return;
        }

        if (isGeneric && !store.genericErrors) {
            return;
        }

        let err = {};
        if (isGeneric) {
            err = serviceErrorSerializer(store.genericErrors);
        } else {
            err = serviceErrorSerializer(store.errors);
        }

        if (!isEmpty(err)) {
            if (err.errCode && GenericErrors.includes(err.errCode)) {
                // setIsGenericError(true);

                store.genericErrors = err;
                store.observed.errors.generics = uuid(); // ensure that generics will be notified every time
                setErrors({});
                setHasError(true);
                return;
            } else {
                setIsGenericError(false);
            }
            setErrors({
                ...err,
                hook,
            });
            setHasError(true);
        } else {
            // handle ALL unhandled errors and throw it as generic error....
            if (isEmpty(err) && !isEmpty(store.errors)) {
                // when err is empty but store.errors is not , it means there's an error but unhandled...
                const errSource = isArray(store.errors) ? store.errors[0] : store.errors;
                const unhandled = serielizeUnhandledErrors(errSource);
                store.genericErrors = unhandled;
                store.observed.errors.generics = uuid();
                setErrors({});
                setHasError(true);
                return;
            }
            setErrors({});
            setHasError(false);
        }
    }, errorObservables);

    useEffect(() => {

    }, [isGenericError]);
    useEffect(() => {
        if (isGeneric) {
            store.genericErrors = null;
        } else {
            store.errors = null;
        }
    }, [errors]);

    return {
        isGenericError,
        errors,
        hasError,
        hasInputError,
    };
}

export default useErrors;
