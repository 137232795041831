import React from 'react';
import {
    Box,
    // Grid,
    Tooltip,
} from '@material-ui/core';
import {
    ColorPicker,
    //  ColorInput, ColorBox,
    ColorPalette,
    createColor,
} from 'material-ui-color';
import classnames from 'classnames';
import useStyles from './styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { Typography } from '../../../../../components/Wrappers/Wrappers';
// import useButtonDesignData from '../useButtonDesignData';

const ColorPickerComp = (props) => {
    // const [color, setColor] = useState(createColor('#000'));
    const classes = useStyles();
    const {
        buttonColor,
        setButtonColor,
        fontColor,
        setFontColor,
        loadButtonColor = false,
        loadFontColor = false,
        loadCustomFontColor = false,
        onSelectTheme = () => {},
    } = props;

    const palette = {
      Purple: '#100239',
      'Dark Blue': '#154D93',
      'Vibrant Blue': '#1CB1F9',
      Mono: '#000000',
      Outline: '#FFFFFF',
    };

    const handleButtonColorChange = (value) => {
        setButtonColor(value);
    };
    const handleFontColorChange = (value) => {
        setFontColor(value);
    };

    const handleSelect = (value) => {
        onSelectTheme(createColor(value).raw);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
            }}
        >
            { loadButtonColor && (
                <Box
                    display={'flex'}
                    justifyContent={'left'}
                    flexDirection={'column'}
                    mb={'59px'}
                >
                    <Typography variant={'body1'} style={{ lineHeight: '16px' }} >
                        Create a Style:
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            marginTop: '6px',
                        }}>
                        <ColorPalette palette={palette} onSelect={handleSelect} />
                        <Tooltip title='Custom'>
                            <Box
                                width={'64px'}
                                display={'flex'}
                                justifyContent={'right'}
                                border={'1px solid rgba(0, 0, 0, 0.23)'}
                            >
                                <ColorPicker value={buttonColor} onChange={handleButtonColorChange} hideTextfield />
                                <ArrowDropDownIcon fontSize='medium' />
                            </Box>
                        </Tooltip>
                    </Box>
                </Box>
            )}
            { loadCustomFontColor && (
                <Box className={classnames([classes.colorPicker], [classes.fontClrMargin])} >
                    <Typography
                        variant='body1'
                        className={classes.colorPickerText}
                    >
                        Font Colour
                    </Typography>
                    <ColorPicker
                        value={fontColor}
                        defaultValue='#000000'
                        onChange={handleFontColorChange}
                        hideTextfield
                    />
                </Box>
            )}
            { loadFontColor && (
                <Box
                    display={'flex'}
                    justifyContent={'left'}
                    flexDirection={'column'}
                    mb={'59px'}>
                    <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        marginTop: '10px',
                    }}>
                            <Box
                                width={'64px'}
                                display={'flex'}
                                justifyContent={'right'}
                                border={'1px solid rgba(0, 0, 0, 0.23)'}
                            >
                                <ColorPicker value={buttonColor} onChange={handleButtonColorChange} hideTextfield />
                                <ArrowDropDownIcon fontSize='medium' />
                            </Box>

                    </Box>
                </Box>
            )}
        </Box>

    );
};

// function FontColorPicker =()=> {

// };

export default ColorPickerComp;
