import { cloneDeep, isEmpty } from 'lodash';
import { v4 as uuid } from 'uuid';
import {
    apiGetSchedules,
    apiAddSchedule,
    apiSearchSchedules,
    apiEditSchedule,
    apiBulkEditSchedules,
    apiBulkDeleteSchedules,
    apiGetSchedulesByIds,
} from './api';

import { getURLPathname, logInfo } from '../../../utils';

// import { handleScheduleError } from '../error/sched_middleware';

export async function getSchedules () {
    this.env.errorStore.action = 'getSchedules';
    this.env.eventsStore.eventsPage = 0;
    this.schedules = [];
    this.scheduleIsLoading = true;
    const publisher = this.env.userStore.publisher;

    let draft;
    if (this.scheduleFilter) {
        if (this.scheduleFilter === 'live') {
            draft = false;
        } else {
            draft = true;
        }
    }
    const res = await apiGetSchedules({
        draft,
        page: this.schedulePage,
        pageLength: this.scheduleRowsPerPage,
        token: publisher.token,
        traceId: this.env.traceId,
    });

    if (res.data &&
        res.data.data &&
        res.data.data.getSchedules) {
        logInfo('response.data', res.data.data);
        this.renderSchedules(res.data.data.getSchedules);
    }
    this.scheduleIsLoading = false;
}

export async function addSchedule (params, navigateToSchedules = true) {
    this.env.errorStore.action = 'addSchedule';
    this.scheduleIsLoading = true;
    this.resetSearchAndFilters();
    const {
        name,
        reference,
        logo,
        type,
        banner,
        genre,
        subGenre,
    } = params;

    const catIds = this.categoryChecked ? [...this.categoryChecked] : [];

    const input = {
        type: type || 'fixture',
        genre,
        subGenre,
        name,
        reference,
        logoUrl: getURLPathname(logo),
        notes: '',
        tags: '',
        categoryIds: sanitizeCategoryIds(catIds),
    };

    if (banner) {
        input.banner = banner;
    }

    const publisher = this.env.userStore.publisher;

    const res = await apiAddSchedule({
        input,
        token: publisher.token,
        traceId: this.env.traceId,
    });

    const metadata = {
        schedule_name: name,
        ip: this.env.userStore.IP || '',
    };

    logInfo('Will Track Intercom Event: ', metadata);
    window.Intercom(
        'trackEvent',
        'Created schedule by add',
        metadata);

    logInfo('Schedule Added: ', res);
    if (navigateToSchedules) {
        this.env.history.push('/admin/schedules');
    } else {
        this.scheduleIsLoading = false;
        return res;
    }
    this.scheduleIsLoading = false;
}

export function updateSchedulePage (page) {
    this.scheduleIsLoading = true;
    this.schedulePage = page;
    this.searchOrGetSchedules();
}

export function searchOrGetSchedules () {
    this.scheduleIsLoading = true;
    this.schedules = [];
    if ((!this.scheduleSearchPrev && this.scheduleSearchText) ||
         (this.scheduleSearchPrev && !this.scheduleSearchText)
    ) {
        this.schedulePage = 0;
        this.scheduleSearchPrev = this.scheduleSearchText;
    }

    if (this.scheduleSearchText) {
        console.log('Make Search Schedule');
        this.searchSchedules({
            name: this.scheduleSearchText,
            categoryName: this.scheduleSearchText,
            draftStatus: this.scheduleFilter,
        });
    } else if (this.scheduleFilter) {
        console.log('Make Search Schedule');
        // this.schedulePage = 0;
        this.searchSchedules({
            draftStatus: this.scheduleFilter,
        });
    } else {
        console.log('Get Schedules');
        this.getSchedules();
    }
    this.scheduleIsLoading = false;
}

export async function searchSchedules ({ name, categoryName, draftStatus, renderSearchResult = true, schedulePage, scheduleRowsPerPage }) {
    this.scheduleIsLoading = true;
    const publisher = this.env.userStore.publisher;
    this.env.errorStore.action = 'searchSchedules';
    console.log('searchSchedules(): ', draftStatus);
    const res = await apiSearchSchedules({
        name,
        categoryName,
        draftStatus,
        page: schedulePage || this.schedulePage,
        pageLength: scheduleRowsPerPage || this.scheduleRowsPerPage,

        token: publisher.token,
        traceId: this.env.traceId,
    });

    if (res.data &&
        res.data.data &&
        res.data.data.searchSchedules) {
        if (renderSearchResult) {
            this.renderSchedules(res.data.data.searchSchedules);
        } else {
            const processedSchedules = this.processSearchResult(res.data.data.searchSchedules);
            this.scheduleIsLoading = false;
            return processedSchedules;
        }
    }

    if (!renderSearchResult) {
        this.scheduleIsLoading = false;
        return [];
    }
    this.scheduleIsLoading = false;
}

export function processSearchResult (source) {
    const edges = source.edges;
    if (!edges || edges.length <= 0) {
        return [];
    }
    return edges.map((edge) => {
        const node = edge.node;
        return {
            ...node,
            label: node.name,
            value: node.id,
            catName: node.categoryNames,
        };
    });
}

export function renderSchedules (source) {
    function mapSchedules (edges) {
        edges.node.label = edges.node.name;
        edges.node.value = edges.node.name;
        return edges.node;
    }

    const edges = source.edges;
    this.schedulesTotalCount = source.totalCount;
    let schedules = [];

    if (edges) {
        schedules = edges.map(mapSchedules);
    }
    console.log('Schedules List : ', schedules);
    this.schedulesUnobserved = cloneDeep(schedules);
    this.schedules = schedules;
    this.scheduleIsLoading = false;
}

export async function editSchedule (params) {
    this.env.errorStore.action = 'editSchedule';
    this.scheduleIsLoading = true;
    this.resetSearchAndFilters();
    const {
        name,
        reference,
        logo,
        type,
        banner,
        genre,
        subGenre,
    } = params;

    let editProps = {};
    if (this.scheduleEditTarget.selectedData) {
        editProps = {
            ...this.scheduleEditTarget.selectedData,
        };

        /* Removed unneccessary edit fields */
       delete editProps.created;
       delete editProps.modified;
       delete editProps.value;
       delete editProps.eventCount;
       delete editProps.categoryNames;
       delete editProps.label;
       delete editProps.publisherId;
    }

    const publisher = this.env.userStore.publisher;

    function getLogoURL () {
        if (logo) {
            return getURLPathname(logo);
        }
        if (logo === '') {
            return '';
        }
        return getURLPathname(editProps.logoUrl);
    }

    if (banner) {
        editProps.banner = banner;
    }

    // const catIds = this.categoryChecked && this.categoryChecked.length > 0 ? this.categoryChecked : editProps.categoryIds;
    const catIds = this.categoryChecked || [];
    const res = await apiEditSchedule({
        input: {
            ...editProps,
            name,
            type: type || 'fixture',
            genre,
            subGenre,
            reference,
            logoUrl: getLogoURL(),
            categoryIds: sanitizeCategoryIds(catIds),
        },
        token: publisher.token,
        traceId: this.env.traceId,
    });
    console.log('editSchedule complete() :', res);
    this.env.history.push('/admin/schedules');
    this.scheduleIsLoading = false;
}

export function getSchedulesFromSelection (selection, action) {
    const schedules = [];
    for (let i = 0; i < selection.length; i++) {
        const index = selection[i].index;
        const schedule = this.schedulesUnobserved[index];

        const newSchedule = {
            id: schedule.id,
            type: schedule.type || 'fixture',
            genre: schedule.genre,
            subGenre: schedule.subGenre,
            name: schedule.name,
            categoryIds: schedule.categoryIds,
        };
        if (action === 'draft') {
            newSchedule.draft = true;
        } else if (action === 'live') {
            newSchedule.draft = false;
        }
        schedules.push(newSchedule);
    }
    return schedules;
}

export function makeSchedulesLive (selection) {
    const schedules = this.getSchedulesFromSelection(selection.data, 'live');
    console.log('');
    console.log('ScheduleService.makeSchedulesLive()', selection);
    console.log('Schedules: ', schedules);
    this.bulkEditSchedules({ schedules });
}

export function makeSchedulesDraft (selection) {
    const schedules = this.getSchedulesFromSelection(selection.data, 'draft');
    console.log('');
    console.log('ScheduleService.makeSchedulesDraft()', selection);
    console.log('Schedules: ', schedules);
    this.bulkEditSchedules({ schedules });
}

export function deleteSchedules (selection) {
    const schedules = this.getSchedulesFromSelection(selection.data);
    this.bulkDeleteSchedules({ schedules });
}

export async function bulkEditSchedules ({ schedules }) {
    this.scheduleIsLoading = true;
    this.env.errorStore.action = 'bulkEditSchedules';
    const publisher = this.env.userStore.publisher;

    const res = await apiBulkEditSchedules({
        schedules,
        token: publisher.token,
        traceId: this.env.traceId,
    });

    console.log('Responses: ', res);
    this.schedulePage = 0;
    this.searchOrGetSchedules();
}

export async function bulkDeleteSchedules ({ schedules }) {
    this.env.errorStore.action = 'bulkDeleteSchedules';
    const publisher = this.env.userStore.publisher;
    const res = await apiBulkDeleteSchedules({
        schedules,
        token: publisher.token,
        traceId: this.env.traceId,
    });

    console.log('Responses: ', res);
    this.schedulePage = 0;
    this.searchOrGetSchedules();
}

export async function getSchedulesForSubscribersFilter () {
    this.env.errorStore.action = 'getSchedulesForSubscribersFilter';
    this.schedules = [];
    this.scheduleIsLoading = true;
    const publisher = this.env.userStore.publisher;

    const res = await apiGetSchedules({
        sort: {
            field: 'name',
            order: 'ASC',
        },
        page: 0,
        pageLength: 300,
        token: publisher.token,
        traceId: this.env.traceId,
    });

    this.env.subscribersStore.updateSchedulesFilter(res);
    this.env.buttonsStore.updateSchedulesFilterForButton(res);
    this.scheduleIsLoading = false;
}

export async function getSchedulesAsFilter () {
    this.env.errorStore.action = 'getSchedulesAsFilter';
    const publisher = this.env.userStore.publisher;
    const res = await apiGetSchedules({
        sort: {
            field: 'created',
            order: 'DESC',
        },
        // draft: false,
        page: 0,
        pageLength: 50,
        token: publisher.token,
        traceId: this.env.traceId,
    });

    const edges = res.data.data.getSchedules.edges;
    const schedulesAsFilter = edges.map((item) => {
        return {
            label: item.node.name,
            value: item.node.id,
            genre: item.node.genre,
            subGenre: item.node.subGenre,
        };
    });

    console.log('Schedules as Filter Rersponse: ', schedulesAsFilter);
    this.schedulesAsFilter = schedulesAsFilter;
    this.observed.schedulesAsFilter = uuid();
}

export async function uploadScheduleLogo ({ file, assetType }) {
    this.env.errorStore.action = 'uploadScheduleLogo';
    const res = await this.env.userStore.uploadAssets({
        file,
        assetType,
    });

    this.assets[assetType] = res.data.data;
    this.observed.assets = {
        [assetType]: uuid(),
    };
}

export async function uploadScheduleBanner ({ file, assetType }) {
    this.env.errorStore.action = 'uploadScheduleBanner';
    const res = await this.env.userStore.uploadAssets({
        file,
        assetType,
    });

    console.log('uploadScheduleBanner(): response:: ', res);
    this.assets[assetType] = res.data.data;
    this.observed.assets = {
        [assetType]: uuid(),
    };
}

export async function getSchedulesByIds (ids) {
    this.env.errorStore.action = 'getSchedulesByIds';

    this.schedules = [];
    this.scheduleIsLoading = true;
    const publisher = this.env.userStore.publisher;

    const res = await apiGetSchedulesByIds({
        ids,
        token: publisher.token,
        traceId: this.env.traceId,
    });

    if (res.data &&
        res.data.data &&
        res.data.data.getSchedules) {
            const schedules = this.processSearchResult(res.data.data.getSchedules);
            this.scheduleIsLoading = false;
            return schedules;
    }
    this.scheduleIsLoading = false;
}

function sanitizeCategoryIds (ids) {
    // remove Uncategorized.... sanitize categories from old data...
    if (isEmpty(ids)) {
        return [];
    }
    return ids.filter(c => (c !== 'Uncategorized'));
}

export function resetSearchAndFilters () {
    this.schedulePage = 0;
    this.scheduleFilter = null;
}
