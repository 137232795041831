
import { useState, useEffect } from 'react';

import industryObjectiveMap from '../../../commons/util/IndustryObjectiveMapping.json';
import Industries from '../../../commons/util/IndustriesList.json';
import Objectives from '../../../commons/util/Objectives.json';
import { logInfo } from '../../../utils';

function usePublisherCompany (props) {
    const {
        publisher,
    } = props;

    const [data, updateData] = useState({
        ...publisher,
        company: publisher.name,
    });
    const [_objectives, setObjectives] = useState(Objectives);
    const [saveDisabled, setSaveDisabled] = useState(true);
    const [touched, setTouched] = useState(false);
    const [initialized, setInitialized] = useState(false);

    function getIndustryObjectives (industryValue) {
        const industry = selectIndustry(industryValue);
        const ocodes = industryObjectiveMap[0][industry.code];
        return Objectives.filter(f => ocodes.includes(f.code));
    }

    function setObjetivesStateByIndustry (industryValue) {
        setObjectives(getIndustryObjectives(industryValue));
    }

    function setData (data) {
        setTouched(true);
        updateData(data);
    }

    function selectIndustry (value) {
        const industry = Industries.filter((item) => {
            if (item.value === value) {
                return true;
            }
            return false;
        });

        if (industry.length > 0) {
            return industry[0];
        }
        return Industries[0];
    }

    function selectObjective (objectivesList, objectiveValue) {
        const filtered = objectivesList.filter((item) => {
            if (item.value === objectiveValue) {
                return true;
            }
            return false;
        });
        if (filtered.length > 0) {
            // return the selected objective
            return filtered[0];
        }
        // or return the first objecitve on the list
        return objectivesList[0];
    }

    function validateInput () {
        if (!touched || !initialized || !data.company) {
            setSaveDisabled(true);
            return;
        }
        setSaveDisabled(false);
    }
    /* When data.industry change , update the list of objectives */
    /* set the selected objective to index 0 */
    useEffect(() => {
        setObjetivesStateByIndustry(data.industry);
        validateInput();
    }, [data]);

    useEffect(() => {
        const objective = selectObjective(_objectives, data.objective);
        if (objective.value !== data.objective) {
            if (!initialized) {
                updateData({
                    ...data,
                    objective: objective.value,
                });
            } else {
                setData({
                    ...data,
                    objective: objective.value,
                });
            }
        }
    }, [_objectives]);

    useEffect(() => {
        logInfo('useOrgProfileHook.useEffect(publisher): : ', publisher);
        updateData({
            ...publisher,
            company: publisher.name,
        });
        setTimeout(() => {
            setInitialized(true);
        }, [1000]);
    }, [publisher]);

    /* Do the initialization */
    useEffect(() => {
        console.log('Industry: ', data.industry);
        console.log('Objective: ', data.objective);
        initialize();
    }, []);

    /*
     * INITIALIZE THE DATA SETS
     * The initialization will have fallback mechanism to handle values not set via selection.
     */
    function initialize () {
        const oldData = {
            ...data,
            company: data.orgName,
        };

        if (data.industry) {
            const industry = selectIndustry(data.industry);
            if (industry.value !== oldData.industry) {
                oldData.industry = industry.value;
            }
        }

        if (!data.industry) {
            oldData.industry = null;
        }
        /* retrieve the objectives selection */

        if (data.objective) {
            const objectives = getIndustryObjectives(data.industry);
            const objective = selectObjective(objectives, data.objective);
            if (objective.value !== oldData.objective) {
                oldData.objective = objective.value;
            }
        }

        updateData({ ...oldData });
        if (data.industry) {
            setObjetivesStateByIndustry(data.industry);
        }
        setSaveDisabled(true);
    }

    return {
        data,
        setData,
        industries: Industries,
        objectives: _objectives,
        saveDisabled,
        setSaveDisabled,
    };
}

export default usePublisherCompany;
