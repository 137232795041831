import React from 'react';

import {
    Box,
} from '@material-ui/core';

import { Typography } from '../../../../components/Wrappers';

import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import {
    CreateOutlined as CreateIcon,
    DateRange,
} from '@material-ui/icons';

/*
 * Note:
 * id field in the column matches ff:
 *      the row field or data field from api response
 *      customCell renderers
 */
export const columns = [
    {
        id: 'name',
        numeric: true,
        disablePadding: false,
        label: 'Link Name',
    },

    {
        id: 'url',
        numeric: true,
        disablePadding: false,
        label: 'Destination URL',
        cellClassName: 'messageCell',
    },

    {
        id: 'actions',
        numeric: true,
        disablePadding: false,
        label: 'Actions',
    },
];

/* Custom Cells */

function actionCell ({ index, row, column, props }) {
    const {
        onEdit,
        onDelete,
        classes,
    } = props;

    return (
        <Box
            display={'flex'}
            style={{
                marginLeft: -12,
            }}
            className={classes.actionCell}
        >
            <IconButton
                color={'primary'}
                onClick= { (e) => {
                    e.preventDefault();
                    console.log('edit row', row);
                    onEdit(row);
                }}
                className={classes.actionCellIcon}
            >
                <CreateIcon />
            </IconButton>
            <IconButton
                onClick={() => {
                    onDelete(row);
                }}
                color={'primary'}
                className={classes.actionCellIcon}
            >
                <DeleteIcon />
            </IconButton>
        </Box>
    );
}

function nameCell ({ index, row, column, props }) {
    const {
        classes,
    } = props;

    return (
            <Typography
                className={classes.nameCell}
                variant={'body2'}
            >
                {row.name}
            </Typography>
    );
}

function urlCell ({ index, row, column, props }) {
    const {
        classes,
    } = props;

    return (
            <Typography
                className={classes.messageText}
                variant={'body2'}
            >
                {row.url}
            </Typography>
    );
}

export const customCells = {
    name: nameCell,
    actions: actionCell,
    url: urlCell,
};

function titleIcon (props) {
    return (
        <Box ml={2} mt={'4px'} mr={3} >
            <DateRange fontSize={'default'} />
        </Box>
    );
}

export const options = {
    titleIcon: titleIcon,
};
