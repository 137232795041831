import { v4 as uuid } from 'uuid';
import { isEmpty } from 'lodash';

import {
    logInfo,
} from '../../../utils';

import {
    apiCreateUser,
    apiGetPublisherUsers,
    apiUpdateUsers,
    apiDeleteUsers,
} from './api';

const ERR_USER_EXIST_IN_COGNITO = 'UsernameExistsException: An account with the given email already exists.';
const ERR_INVALID_PASSWORD = 'InvalidPasswordException: Password did not conform with password policy: Password not long enough';

const __localErrors = [
    ERR_USER_EXIST_IN_COGNITO,
    ERR_INVALID_PASSWORD,
];

function isError (message) {
    let hasError = false;
    for (let i = 0; i < __localErrors.length; i++) {
        const err = __localErrors[i];
        if (message.indexOf(err) !== -1) {
            hasError = true;
            break;
        }
    }
    return hasError;
}

export async function createPublisherUser ({ publisher, newUser }) {
    this.env.errorStore.action = 'createPublisherUser';
    this.env.showProgress();
    this.user = this.env.userStore.user;
    this.aws = this.env.userStore.aws;

    logInfo('createPublisherUser() -> ', newUser);

    const res = await apiCreateUser({
        input: {
            publisherId: publisher.publisherId,
            orgId: publisher.orgId,
            email: newUser.email,
            password: newUser.password,
            firstName: newUser.firstName,
            lastName: newUser.lastName,
            phone: newUser.phone,
        },
        token: this.user.token,
        traceId: this.env.traceId,
    });
    this.env.closeProgress();

    const data = res.data.data;
    if (data.createPublisherUser.error) {
        const message = data.createPublisherUser.error.message;
        if (isError(message)) {
            this.warning = {
                title: 'Create User Failed',
                message,
            };
            this.observed.warning = uuid();
        }
    }
    this.getPublisherUsers({ publisher });
}

export async function getPublisherUsers ({ publisher }) {
    this.env.errorStore.action = 'getPublisherUsers';
    this.env.showProgress();
    this.user = this.env.userStore.user;
    if (isEmpty(this.user.token)) {
        return;
    }
    this.aws = this.env.userStore.aws;
    const page = this.pubUsersPageInfo.page + 1;

    const res = await apiGetPublisherUsers({
        input: {
            publisherId: publisher.publisherId,
            page,
        },
        token: this.user.token,
        traceId: this.env.traceId,
    });

    this.env.closeProgress();

    const data = res.data.data;
    const source = data?.getPublisherUsers;

    if (!source || isEmpty(source)) {
        return;
    }

    if (!isEmpty(source.edges)) {
        const users = source.edges.map((edge) => {
            const userData = edge.node;
            return {
                ...userData,
            };
        });
        this.publisherUsers = users;
        this.observed.publisherUsers = uuid();
    }
}

export async function updatePublisherUsers ({ publisher, publisherUser }) {
    this.env.errorStore.action = 'updatePublisherUsers';
    this.env.showProgress();
    this.user = this.env.userStore.user;
    this.aws = this.env.userStore.aws;

    logInfo('updatePublisherUsers() -> ', publisherUser);

    // NOTE : EMAIL IS REQUIRED but ONLY USED TO REFERENCE COGNITO

    const res = await apiUpdateUsers({
        input: {
            users: [{
                id: publisherUser.id,
                email: publisherUser.email,
                publisherId: publisher.publisherId,
                orgId: publisher.orgId,
                firstName: publisherUser.firstName,
                lastName: publisherUser.lastName,
            }],
        },
        token: this.user.token,
        traceId: this.env.traceId,
    });
    this.env.closeProgress();

    const data = res.data.data;
    if (data.updatePublisherUsers.error) {
        const message = data.updatePublisherUsers.error.message;
        if (isError(message)) {
            this.warning = {
                title: 'Update User Failed',
                message,
            };
            this.observed.warning = uuid();
        }
    }
    this.getPublisherUsers({ publisher });
}

export async function deletePublisherUsers ({ publisher, publisherUser }) {
    this.env.errorStore.action = 'deletePublisherUsers';
    this.env.showProgress();
    this.user = this.env.userStore.user;
    this.aws = this.env.userStore.aws;

    logInfo('deletePublisherUsers() -> ', publisherUser);

    const res = await apiDeleteUsers({
        input: {
            publisherId: publisher.publisherId,
            ids: [publisherUser.id],
        },
        token: this.user.token,
        traceId: this.env.traceId,
    });
    this.env.closeProgress();

    const data = res.data.data;
    if (data.deletePublisherUsers.error) {
        const message = data.deletePublisherUsers.error.message;
        if (isError(message)) {
            this.warning = {
                title: 'Delete User Failed',
                message,
            };
            this.observed.warning = uuid();
        }
    }
    this.getPublisherUsers({ publisher });
}

export async function activatePublisherUsers ({ publisher, publisherUser }) {
    this.env.errorStore.action = 'activatePublisherUsers';
    this.env.showProgress();
    this.user = this.env.userStore.user;
    this.aws = this.env.userStore.aws;

    logInfo('activatePublisherUsers() -> ', publisherUser);

    /*
     * NOTE: Setting the user.status to active, publisher-user-service will also enable user in cognito..
     * the service will detect the status = active and will trigger cognito to enable user.
     *
     * EMAIL IS REQUIRED but ONLY USED TO REFERENCE COGNITO
     */
    const res = await apiUpdateUsers({
        input: {
            users: [{
                publisherId: publisher.publisherId,
                orgId: publisher.orgId,
                id: publisherUser.id,
                email: publisherUser.email,
                status: 'active',
            }],
        },
        token: this.user.token,
        traceId: this.env.traceId,
    });
    this.env.closeProgress();

    const data = res.data.data;
    if (data.updatePublisherUsers.error) {
        const message = data.updatePublisherUsers.error.message;
        if (isError(message)) {
            this.warning = {
                title: 'Activate User Failed',
                message,
            };
            this.observed.warning = uuid();
        }
    }
    this.getPublisherUsers({ publisher });
}
