import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import {
    Grid,
} from '@material-ui/core';

import BasicDetails from './basic';
// import Security from './password';
import CompanyDetails from './company';
import PlanDetails from './plan';
import AccountSettings from './account';
// import ButtonPanels from './ButtonPanels';
import Tabs from './tabs';
// import { useUserStore } from '../../store/models';
import useSuperAdminPublisher from './useSuperAdminPublisher';
import useModuleErrorDialog from '../../store/models/error/useModuleErrorDialog';
import { getTabByPath } from './profileUtils';
import { logInfo } from '../../utils';

function SuperAdminProfile (props) {
    const {
        location,
    } = props;

    const [tab, setTab] = useState(getTabByPath(location.pathname));

    const {
        publisher,
        errors,
        saveBasicDetails,
        saveAccountSettings,
        saveCompanyInformation,
        savePlan,
        renderSuperAdminConfirmation,
        setType,
        plans,
        publisherUsers,
        resetErrorState,
    } = useSuperAdminPublisher();

    const {
        renderErrorDialog,
    } = useModuleErrorDialog({
        errors,
        onCloseError: () => {
            console.log('Closing Errorss...');
            resetErrorState();
        },
    });

    useEffect(() => {
        if (publisher) {
            logInfo('initialized intercom on superadmin access..');
            window.Intercom('boot', {
                app_id: window.APP_ID,
                email: publisher.email,
                user_id: publisher.publisherId,
                user_hash: publisher.intercomUserHash,
                hide_default_launcher: true,
            });
        }
    }, [publisher]);

    return (
        <React.Fragment>

            <Grid container spacing={6}>
                <Tabs
                    tabIndex={tab}
                    onTabChange={(newTab) => {
                        setTab(newTab);
                        setType(newTab);
                    }}
                />
                <Grid item xs={12}>

                    { tab === 0 && (
                        <Grid>
                            <BasicDetails
                                onDataChange={(data) => {
                                    console.log('Data: ', data);
                                }}
                                user={publisher}
                                inProgress={false}
                                publisher={publisher}
                                onSave={(data) => {
                                    saveBasicDetails(data);
                                }}
                            />
                        </Grid>
                    )}

                    { tab === 1 && (
                        <Grid>
                            <AccountSettings
                                onDataChange={(data) => {
                                    console.log('Data: ', data);
                                }}
                                inProgress={false}
                                publisher={publisher}
                                onSave={(data) => {
                                    saveAccountSettings(data);
                                }}
                                publisherUsers={publisherUsers}
                            />
                        </Grid>
                    )}

                    { tab === 2 && (
                        <CompanyDetails
                            onDataChange={(data) => {
                                console.log('Data: ', data);
                            }}
                            inProgress={false}
                            publisher={publisher}

                            onSave={(data) => {
                                saveCompanyInformation(data);
                            }}
                            publisherLogoError={{}}
                        />
                    )}

                    { tab === 3 && (
                        <PlanDetails
                            onDataChange={(data) => {}}
                            user={publisher}
                            publisher={publisher}
                            plans={plans}

                            onSavePlan={(data, expiryDateData) => {
                                savePlan(data, expiryDateData);
                            }}
                        />
                    )}
                </Grid>
            </Grid>
            { renderErrorDialog() }
            { renderSuperAdminConfirmation() }
        </React.Fragment>
    );
}

SuperAdminProfile.propTypes = {

};

SuperAdminProfile.defaultProps = {

};

export default observer(SuperAdminProfile);
