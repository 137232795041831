import React, { useState } from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import { PropTypes } from 'prop-types';

import {
    Grid,
    Box,
} from '@material-ui/core';

import {
    Typography,
} from '../../../components/Wrappers/Wrappers';

import useStyles from './scheduleEventBanner.styles';

import ImageUploader from '../../../commons/imageUploader/ImageUploader';
import ButtonProgress from '../../../commons/ButtonProgress';
import { DEFAULT_IMAGE } from '../../../commons/imageUploader/DefaultEventBanner';

import useImageUpload from '../../hooks/useImageUploadDynamicStore';
import useImageRenderable from '../../hooks/useImageRenderable';
// import Select from '../../../commons/select/Select';

import {
    AssetType,
    configButtonWidth as configButton,
    // DefaultURLs,
    // ScheduleBannerOptions,
} from '../../../utils/constants';
import {
    // useEventsStore,
    useSchedulesStore,
} from '../../../store/models';

const EventBanner = (props) => {
    // const eventsStore = useEventsStore();
    const schedulesStore = useSchedulesStore();
    const {
        pageTitle,
        input,
        setInput,
        onSave,
        hasValidationFieldError,
    } = props;

    const classes = useStyles();
    const [touched, setTouched] = useState(false);

    function updateSavableState (url) {
        // console.log('ScheduleEventBanner.updateSavableState()', input);
        setTouched(true);
        setInput({
            ...input,
            banner: url,
        });
    };

    const {
        image: eventBannerImage,
    } = useImageRenderable({
        imageSource: input.banner,
        customDefaultImage: DEFAULT_IMAGE,
    });

    async function validateBanner ({ image }) {
        // check the size of image
        const promise = new Promise((resolve, reject) => {
            const img = new Image();
            img.src = image.data_url;

            img.onload = function () {
                console.log('width: ', img.width);
                console.log('height: ', img.height);

                if (img.width === configButton.EVENT_BANNER_WIDTH &&
                    img.height === configButton.EVENT_BANNER_HEIGHT) {
                    resolve({ isValid: true });
                    return;
                }

                resolve({
                    isValid: false,
                    errorMessage: 'The size of this image must be 970px wide x 250px high.',
                });
            };
        });

        return promise;
    }

    const {
        image: eventBanner,
        upload: uploadImage,
        isUploading,
       //  setIsUploading,
        deleteImage: deleteLogo,
        imageError,
    } = useImageUpload({
        assetType: AssetType.SCHEDULE_BANNER,
        observableDataSource: schedulesStore.observed.assets.scheduleBanner,
        consumableDataSource: schedulesStore.assets.scheduleBanner,
        setStateFunc: updateSavableState,
        customDefaultImage: eventBannerImage,
        store: schedulesStore,
        uploadMethod: 'uploadScheduleBanner',
        shouldCheckFilesize: true,
        sizeLimit: 200,
        customImageValidation: validateBanner,
        waitOnUpload: true,
    });

    function renderBannerError () {
        if (!imageError) {
            return <React.Fragment></React.Fragment>;
        }
        return (
                <Typography
                variant={'caption'}
                color={'error'}
                weight={'medium'}
            >
                {imageError}
            </Typography>
        );
    }

    function saveDisabled () {
        if (!touched) {
            return true;
        }
        if (hasValidationFieldError) {
            return true;
        }
        if (imageError) {
            return true;
        }
        return false;
    }

    return (
        <Grid
            item
            className={classes.mainContainer}
            container>
            <Box
                display={'flex'}
                flexDirection={'column'}
                width={600}
                className={classes.boxStyling}
                data-ecal-id="schedule-banner-image"
            >
                <Typography
                    variant={'h5'}
                    weight={'medium'}
                    style={{ marginBottom: 30 }}
                    data-intercom-target='event-banner-title'
                >
                    {pageTitle} Event Banner
                </Typography>

                <Grid className={classNames([classes.sectionContentUpload, classes.bottomSpace])}>
                    <Grid
                        item
                        xs={12}
                        className={classes.uploadSection}
                        >
                            <Typography
                                    variant={'body1'}
                                    className={classes.imageTitle}
                                >
                                    <b>Upload an Event Banner</b><br/>
                                    This banner image will appear in an Event Display, for every event in this schedule. Upload JPG, PNG, or GIF images up to 200kb in size, and exactly 970px (width) x 250px (height) in dimension. <br/>

                            </Typography>
                            <ImageUploader
                                imageType={'banner'}
                                layoutDesign={'spaceBetween'}
                                hasCreate={true}
                                hasDelete={true}
                                createLabel={'Upload'}
                                imageWidth='350px'
                                imageHeight='90px'
                                imageData={eventBanner}

                                isUploading={isUploading}
                                onImageSelected={(images) => {
                                    uploadImage(images);
                                }}

                                onImageDelete={(files) => {
                                    deleteLogo();
                                }}
                                dataIntercomTarget={'event-banner-image'}
                            />
                            { renderBannerError() }
                    </Grid>

                </Grid>

                <Box
                    display={'flex'}
                    justifyContent={'flex-end'}
                >
                    <ButtonProgress
                        // disabled={hasError}
                        showProgress={isUploading}
                        label='Save'
                        disabled={saveDisabled()}
                        onClick={() => {
                            onSave();
                        }}
                        dataIntercomTarget='schedule-save-button'
                    />
                </Box>
            </Box>
        </Grid>
    );
};

EventBanner.propsTypes = {
    input: PropTypes.object,
    setInput: PropTypes.func,
    onSave: PropTypes.func,
    pageTitle: PropTypes.string,
    isManagedByFeed: PropTypes.bool,
    hasValidationFieldError: PropTypes.bool,
    isLoading: PropTypes.bool,
};

EventBanner.defaultProps = {
    input: {},
    setInput: () => {},
    onSave: () => {},
    pageTitle: '',
    isManagedByFeed: false,
    hasValidationFieldError: false,
    isLoading: true,
};

export default observer(EventBanner);

/*

 <Grid
                        className={classes.bannerOption}
                    >
                        <Select
                            marginBottom={'0px'}
                            id='age'
                            name='age'
                            value={input.bannerOption}
                            labelHeading= 'Event banner option'
                            placeHolder='Event banner option'
                            handleChange={(e, data) => {
                                console.log(' value: ', data.props.value);
                                setInput({
                                    ...input,
                                    bannerOption: data.props.value,
                                });
                            }}

                            dataForDropdown={ScheduleBannerOptions}
                            helperText={'When do you want the event banner to show up in your event.'}
                        />
                    </Grid>
*/
