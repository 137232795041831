export function schedulesQuery (vars) {
    return {
        query: `query ExportSubscribers($input: ScheduleFilterInput!  $sort: sort , $pageInput: schedulePageInput ) {

            getSchedules( ScheduleFilterInput:$input, ScheduleSortInput: $sort, pageInput: $pageInput ) {
                totalCount
                edges {
                  node {
                    id
                    name
                    publisherId
                    draft
                    categoryNames
                    categoryIds
                    reference
                    created
                    modified
                    eventCount
                    logoUrl
                    banner
                    genre
                    subGenre
                  }
                  cursor
                }
                pageInfo {
                  endCursor
                  hasNextPage
                }
            } 
        }
        `,
        variables: vars,
    };
}

export function queryGetRootCategory (vars) {
  return {
      query: `query categories{

        getRootCategories {
            totalCount
            edges {
              node {
                id
                name
                publisherId
                path
                rootId
                parentId
                hasChildren
              }
              cursor
            }
            pageInfo {
              endCursor
              hasNextPage
            }
          } 
      }
      `,
      variables: vars,
  };
}

export function queryGetCategoriesByFilter (vars) {
  return {
      query: `query categories($filter: CategoryFilterInput!){

        getCategoriesFilterBy (filter:$filter) {
            totalCount
            edges {
              node {
                id
                name
                publisherId
                path
                rootId
                parentId
                hasChildren
              }
              cursor
            }
            pageInfo {
              endCursor
              hasNextPage
            }
          } 
      }
      `,
      variables: vars,
  };
}

export function queryAddCategory (vars) {
  return {
      query: `mutation categories($input: AddCategoryInput!){

        addCategory (input:$input) {
            totalCount
            edges {
              node {
                id
                name
                publisherId
                path
                rootId
                parentId
                hasChildren
              }
              cursor
            }
            pageInfo {
              endCursor
              hasNextPage
            }
          } 
      }
      `,
      variables: vars,
  };
}

export function queryDeleteCategory (vars) {
  return {
      query: `mutation categories($input: DeleteCategoryInput!){
        deleteCategory (input:$input) {
            totalCount
            edges {
              node {
                id
                name
                publisherId
                path
                rootId
                parentId
                hasChildren
              }
              cursor
            }
            pageInfo {
              endCursor
              hasNextPage
            }
          } 
      }
      `,
      variables: vars,
  };
}

export function querySearchSchedules (vars) {
  return {
      query: `query Schedules($searchInput: scheduleSearchInput!  $sortInput: sort , $pageInput: schedulePageInput ) {

        searchSchedules( searchInput:$searchInput, sortInput: $sortInput, pageInput: $pageInput ) {
              totalCount
              edges {
                node {
                  id
                  name
                  publisherId
                  draft
                  categoryNames
                  categoryIds
                  reference
                  created
                  modified
                  eventCount
                  logoUrl
                  banner
                  genre
                  subGenre
                }
                cursor
              }
              pageInfo {
                endCursor
                hasNextPage
              }
          } 
      }
      `,
      variables: vars,
  };
}

/* Schedule MUTATIONS */

export function queryAddSchedule (vars) {
  return {
      query: `mutation schedule($input: AddScheduleInput!){

        addSchedule (input:$input) {
            totalCount
            edges {
              node {
                id
                name
                draft
                type
                note
                publisherId
                publisherOrgId
                reference
                logoUrl
                tags
                categoryIds
                categoryNames
                banner
                genre
                subGenre
              }
              cursor
            }
            pageInfo {
              endCursor
              hasNextPage
            }
          } 
      }
      `,
      variables: vars,
  };
}

export function queryEditSchedule (vars) {
  return {
      query: `mutation schedule($input: EditScheduleInput!){

        editSchedule (input:$input) {
            totalCount
            edges {
              node {
                id
                name
                type
                draft
                note
                publisherId
                publisherOrgId
                reference
                logoUrl
                tags
                categoryIds
                categoryNames
                banner
                genre
                subGenre
              }
              cursor
            }
            pageInfo {
              endCursor
              hasNextPage
            }
          } 
      }
      `,
      variables: vars,
  };
}

export function queryBulkEditSchedules (vars) {
  return {
      query: `mutation schedule($schedules: [ScheduleInput]){

        editSchedules (schedules:$schedules) {
            totalCount
            edges {
              node {
                id
                name
                type
                draft
                note
                publisherId
                publisherOrgId
                reference
                logoUrl
                tags
                categoryIds
                categoryNames
                genre
                subGenre
              }
              cursor
            }
            pageInfo {
              endCursor
              hasNextPage
            }
          } 
      }
      `,
      variables: vars,
  };
}

export function queryBulkDeleteSchedules (vars) {
  return {
      query: `mutation schedule($schedules: [ScheduleInput]){
        deleteSchedules (schedules:$schedules) {
            totalCount
            edges {
              node {
                id
                name
                type
                draft
                note
                publisherId
                publisherOrgId
                reference
                logoUrl
                tags
                categoryIds
                categoryNames
                genre
                subGenre
              }
              cursor
            }
            pageInfo {
              endCursor
              hasNextPage
            }
          } 
      }
      `,
      variables: vars,
  };
}
