import React, { useEffect } from 'react';
import {
    Grid,
    Box,
} from '@material-ui/core';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import { Typography } from '../../../components/Wrappers';
import Table from '../../../commons/tables/EventTable';
import Widget from '../../../components/Widget';
import useStyles from '../schedule.styles';
import useScheduleNavigation from '../useScheduleNavigation';
import useConfirmationDialog from '../../../commons/dialog/useConfirmationDialog';
import useAlertDialog from '../../../commons/dialog/useAlertDialog';
import {
    useEventsStore,
    useSchedulesStore,
    // useUserStore,
    useEnvStore,
} from '../../../store/models';

const Events = () => {
    const classes = useStyles();
    const eventsStore = useEventsStore();
    const schedulesStore = useSchedulesStore();
    // const { search } = useLocation();
    const { id } = useParams();
    // const userStore = useUserStore();
    const envStore = useEnvStore();

    useEffect(() => {
        // const queryParams = new URLSearchParams(search);
        // const scheduleName = queryParams.get('scheduleName');
        schedulesStore.loadEditTarget();
        console.log('schedulesStore.scheduleEditTarget', schedulesStore.scheduleEditTarget);
        const scheduleName = schedulesStore.scheduleEditTarget?.selectedData.name;
        // const splitPath = path.split('/');
        eventsStore.selectedScheduleId = id;
        eventsStore.selectedScheduleName = scheduleName;
        eventsStore.getEvents(eventsStore.selectedScheduleId);
    }, []);

     const {
        handleAddEvent,
      } = useScheduleNavigation();

    const handleAddEventClick = (e) => {
        const scheduleId = eventsStore.selectedScheduleId;
        envStore.setPageInfo(
            'admin-event-edit-target',
            null
        );
        handleAddEvent(e, scheduleId);
    };

    const {
        renderDialog: renderNormalDeleteDialog,
        openDialog: openNormalDeleteConfirmation,
    } = useConfirmationDialog({
        title: 'Delete Event',
        message: 'Are you sure you want to delete this event?',
        confirmLabel: 'Yes',
        cancelLabel: 'Cancel',
        showSecondaryButton: true,
        onConfirm: () => {
            const eventData = eventsStore.eventEditTarget.selectedData;
            const selections = [eventData.id];
            console.log('Event Data to Delete: ', selections);
            eventsStore.bulkDeleteEvents({ eventIds: selections });
        },
    });

    const {
        renderDialog: renderManagedByFeedDeleteDialog,
        openDialog: openManagedByFeedDeleteConfirmation,
    } = useAlertDialog({
        title: 'Delete Event',
        message: 'Feed driven events cannot be deleted manually from your admin. Please contact the support team via Live Chat or your Success Manager directly.',
        confirmLabel: 'Ok',
        showSecondaryButton: false,
        onConfirm: () => {},
    });

    const {
        renderDialog: renderDuplicateEventDialog,
        openDialog: openDuplicateEventAlert,
    } = useAlertDialog({
        title: 'Note!',
        message: 'You cannot duplicate a feed driven event.',
        confirmLabel: 'Ok',
        showSecondaryButton: false,
        onConfirm: () => {},
    });

    function isManagedByFeed () {
        return eventsStore?.eventEditTarget?.selectedData.data.reference;
    }

    return (
        <React.Fragment>
            <Grid container>
                <Grid item xs={12}>
                    <Widget
                        className={classes.widgetContainer}
                        buttonText={'Add'}
                        title="Manage Events"
                        handleAdd={handleAddEventClick}
                        intercomAddTarget='add-event'
                        inheritHeight disableWidgetMenu>
                        <Box
                            justifyContent={'space-between'}
                            display={'flex'}
                            alignItems={'flex-start'}
                        >
                            <Typography variant='body2'>
                                Your events are delivered directly into the calendar of subscribers of this schedule. Pro tip: Add event details rich with information, timely reminders and action links to drive engagement and response.
                            </Typography>
                        </Box>
                    </Widget>
                    <Box
                        justifyContent={'space-between'}
                        display={'flex'}
                        alignItems={'flex-start'}
                        marginBottom={'16px'}
                    // marginTop={'32px'}
                    >
                        <Table
                            tableData={eventsStore.events}
                            currentPage={eventsStore.eventsPage}
                            totalCount={eventsStore.eventsTotalCount}
                            onPageChange={(page) => {
                                eventsStore.updateEventPage(page);
                            }}
                            onSearch={(value) => {
                                console.log('on Search() ', value);
                                eventsStore.eventSearchText = value;
                                eventsStore.searchOrGetEvents();
                            }}
                            onDuplicateEvent={() => {
                                eventsStore.loadEventsForEdit();
                                if (eventsStore.eventEditTarget.selectedData.data.reference !== '') {
                                    openDuplicateEventAlert();
                                    return;
                                }
                                eventsStore.eventEditTarget.id = '';
                                eventsStore.eventEditTarget.duplicateEvent = true;
                                eventsStore.eventEditTarget.name = eventsStore.eventEditTarget.selectedData.name;

                                eventsStore.duplicateEventDataFromSelectedItem();
                                console.log('eventDuplicateTarget', eventsStore.eventEditTarget);
                                envStore.setPageInfo(
                                    'admin-event-edit-target',
                                    eventsStore.eventEditTarget
                                );
                                envStore.history.push(`/admin/schedules/${eventsStore.selectedScheduleId}/events/add`);
                            }}

                            onEditEvent={() => {
                                envStore.history.push(`/admin/schedules/${eventsStore.selectedScheduleId}/events/edit`);
                            }}

                            onDeleteEvent={() => {
                                // openDeleteConfirmation();
                                eventsStore.loadEventsForEdit();
                                // console.log('eventsStore.eventEditTarget', eventsStore.eventEditTarget);
                                if (isManagedByFeed()) {
                                    openManagedByFeedDeleteConfirmation();
                                } else {
                                    openNormalDeleteConfirmation();
                                }
                                // openManagedByFeedDeleteConfirmation();
                            }}

                            onActionIconClick={(value, data) => {
                                eventsStore.eventEditTarget = {
                                    id: value,
                                    data: data.rowData,
                                    scheduleId: schedulesStore.scheduleEditTarget?.selectedData?.id,
                                    scheduleName: schedulesStore.scheduleEditTarget?.selectedData?.name,
                                };
                                eventsStore.eventDataFromSelectedItem();
                                envStore.setPageInfo(
                                    'admin-event-edit-target',
                                    eventsStore.eventEditTarget
                                );
                            }}

                            onSetItemsLive={(data) => {
                                // console.log('Schedule.onSetItemsLive() ', data);
                                eventsStore.makeEventsLive(data);
                            }}

                            onSetItemsDraft={(data) => {
                                // console.log('Schedule.onSetItemsDraft() ', data);
                                eventsStore.makeEventsDraft(data);
                            }}
                            onDeleteItems={(data) => {
                                // console.log('Schedule.onDeleteItems() ', data);
                                eventsStore.deleteEvents(data);
                            }}
                            onFilter={(value) => {
                              // console.log('on events Filter() ', value);
                              eventsStore.eventFilter = value;
                              eventsStore.eventsPage = 0;
                              eventsStore.searchOrGetEvents();
                              // eventsStore.eventFilter = '';
                          }}

                            showLoading={eventsStore.eventIsLoading}
                            scheduleEditTargetName= {eventsStore.selectedScheduleName}
                        />
                    </Box>
                </Grid>
            </Grid>
            { renderManagedByFeedDeleteDialog() }
            { renderNormalDeleteDialog() }
            { renderDuplicateEventDialog() }
        </React.Fragment>
    );
};

export default observer(Events);
