import { useState, useEffect } from 'react';

import {
    useSchedulesStore,
    useButtonsStore,
} from '../../../../store/models';
import useStoreModuleDependency from '../../../hooks/useStoreModuleDependency';
import useRemoteSearch from '../../../../commons/ecal/selectsearch/useRemoteSearch';

function useScheduleData (props) {
    const schedulesStore = useSchedulesStore();
    const buttonsStore = useButtonsStore();
    const {
        dataSource,
        onDataChange,
        updateSavable,
    } = props;

    const {
        dataProvider: schedules,
        updateProvider,
        setSelected: setRemoteSelected,
        updatePreselectedProvider,
    } = useRemoteSearch();

    async function getPreselectedSchedules () {
        if (dataSource.display?.schedules && dataSource.display?.schedules.length > 0) {
            const selected = await schedulesStore.getSchedulesByIds(dataSource.display.schedules);
            updatePreselectedProvider(selected);
            // console.log('getPreselectedSchedules(): ', selected);
        }
    }

    const [scheduleData, setScheduleData] = useState({
        schedules: dataSource.display.schedules,
        categories: dataSource.display.categories,
        text: dataSource.display.text,
        scheduleOption: dataSource.display.scheduleOption ? dataSource.display.scheduleOption : '1',
        isAutoSubscribe: dataSource.display.isAutoSubscribe,
        /* add additional fields here */
    });
    const [schedulesToSearch, setSchedulesToSearch] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        return () => {
            if (typeof onDataChange === 'function') {
                onDataChange();
            }
        };
    }, []);

    useEffect(() => {
        if (buttonsStore.buttonSchedules) {
            const newButtonSchedules = buttonsStore.buttonSchedules;
            // console.log('useScheduleData().useEffect(buttonSchedules)', newButtonSchedules);
            setSchedulesToSearch([
                ...newButtonSchedules,
            ]);
            updateProvider([
                ...newButtonSchedules,
            ]);
        }
    }, [buttonsStore.observed.buttonSchedules]);

    useEffect(() => {
        // console.log('useScheduleData().useEffect().schedulesStore.scheduleIsLoading', schedulesStore.scheduleIsLoading);
        if (schedulesStore.scheduleIsLoading) {
            setLoading(true);
        } else {
            setLoading(false);
        }
    }, [schedulesStore.scheduleIsLoading]);

    useEffect(() => {
        setScheduleData({
            schedules: dataSource.display.schedules,
            categories: dataSource.display.categories,
            text: dataSource.display.text,
            scheduleOption: dataSource.display.scheduleOption ? dataSource.display.scheduleOption : '1',
            isAutoSubscribe: dataSource.display.isAutoSubscribe,
        });
    }, [dataSource]);

    /* do data computation before updating the input states */
    /* Or update the input Source for Saving without triggering state change things */
    function updateScheduleData (data) {
        // console.log('updateScheduleData', data);
        setScheduleData(data);
        updateSavable({
            ...dataSource,
            display: { ...data },
        });
    }

    useStoreModuleDependency({ initStore });
    function initStore () {
        schedulesStore.getSchedulesForSubscribersFilter();
        getPreselectedSchedules();
    }

    useStoreModuleDependency({ initStore });

    function updateScheduleSelection (data) {
        const schedules = [
            ...data,
        ];

        setRemoteSelected(schedules);

        updateScheduleData({
            ...scheduleData,
            schedules,
        });
    }

    async function searchSchedule (search) {
        const searchedSchedules = await schedulesStore.searchSchedules({
            name: search,
            categoryName: search,
            draftStatus: '',
            renderSearchResult: false,
            schedulePage: 0,
            scheduleRowsPerPage: 300,
        });
        // console.log('useScheduleData().searchSchedule() searchedSchedules: -> ', searchedSchedules);
        updateProvider([...searchedSchedules]);
    }

    return {
        scheduleData,
        schedulesToSearch,
        updateScheduleData,

        schedules,
        searchSchedule,
        updateScheduleSelection,
        showProgress: loading,
    };
}

export default useScheduleData;
