import { isEmpty } from 'lodash';
import {
    validateError,
    ErrorAPICode,
    ErrorAPIReasons,
    ErrorAPIMessages,
    ErrorAPIErrors,
    errorMap,
} from '../../error/error_map';

const errorReasons = { ...ErrorAPIReasons };
const errorCodes = { ...ErrorAPICode };
const errorMessages = {
    ...ErrorAPIMessages,
    USER_NOT_IN_COGNITO: 'User doesn\'t exist in cognito user pool',
    NON_CUSTOM_PLAN: 'only custom and ecal plans can be updated. please update plan and plan details through the provider (chargify or aws)',
    GIVEN_PARAMS_NOT_VALID: 'given parameter is not valid',
};

const errorErrors = {
    ...ErrorAPIErrors,
     RESOURCE_ALREADY_EXISTS: 'RESOURCE_ALREADY_EXISTS',
     RESOURCE_NOT_FOUND: 'RESOURCE_NOT_FOUND',
};

const customErrorMap = {
    ...errorMap,

    USER_NOT_IN_COGNITO: (err) => {
        console.log('publisherStore.serviceError.customErrorMap -> ', err);
        return {
            title: 'Migration Warning',
            message: 'Account email is updated. User email is not migrated yet in Cognito user pool.',
        };
    },

    NON_CUSTOM_PLAN: () => {
        return {
            title: 'Oops',
            message: 'Only custom and ecal plans can be updated. please update plan and plan details through the provider (chargify or aws).',
        };
    },

    RESOURCE_NOT_FOUND: (err) => {
        return {
            title: 'Resource not found',
            message: err.message,
            text: err.message,
        };
    },

    GIVEN_PARAMS_NOT_VALID: (err) => {
        return {
            title: 'Invalid Search',
            message: 'Your Search Input is empty',
            text: err.message,
            errCode: 'InvalidSearch',
        };
    },
};

export function serviceErrorSerializer (errors) {
    if (isEmpty(errors)) {
        return {};
    }
    const options = {
        errorMap: customErrorMap,
        reasons: errorReasons,
        codes: errorCodes,
        messages: errorMessages,
        errors: errorErrors,
    };
    const error = validateError(errors[0], options);
    return error;
}
