import React from 'react';
import {
    Grid,
    Box,
    TextField,
    Radio,
    FormControlLabel,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import { Typography } from '../../../../components/Wrappers/Wrappers';
import ColorPicker from './colorPicker/ColorPicker';
// import PillPurpleButtonSVG from './pill/PillPurpleSVG';
// import PillPurpleButtonSVG from './pill/PillDarkBlueSVG';
// import PillPurpleButtonSVG from './pill/PillVibrantBlueSVG';
// import PillPurpleButtonSVG from './pill/PillMonoDarkSVG';
// import PillPurpleButtonSVG from './pill/PillOutlineSVG';
// import PillPurpleButtonSVG from './pill/PillCustomVibrantBlueSVG';

import pillImage from '../../../../commons/images/buttons/PillButton.png';
import roundedImage from '../../../../commons/images/buttons/RoundedButton.png';
import squareImage from '../../../../commons/images/buttons/SquareButton.png';
import iconImage from '../../../../commons/images/buttons/RoundedIcon.png';
// import SquareButtonImg from './SquareButtonSVG';
import useStyles from '../../buttons.styles';
import ButtonProgress from '../../../../commons/ButtonProgress';
// import ButtonEditor from './ButtonEditor';
import { useTouchContext } from '../../../../app/context/TouchContext';

import {
    svgComponent,
} from './utils';
import useButtonTemplateTheme from './useButtonTemplateTheme';
import { configButtonWidth } from '../../../../utils/constants';

const {
  BUTTON_MAX_WIDTH,
  BUTTON_MIN_WIDTH,
  BUTTON_ICON_MAX_WIDTH,
  BUTTON_ICON_MIN_WIDTH,
} = configButtonWidth;

const ButtonDesign = (props) => {
    const classes = useStyles();

    const {
        buttonDesignData,
        updateDesignData,
        onSave,
        progress,
        saveDisabled,
        errors,
        updateTextMatrix,
    } = props;

    const {
        template,
        updateTemplate,
        selectTheme,
    } = useButtonTemplateTheme({
        buttonDesignData,
        updateDesignData,
    });

    const handleTemplateChange = (event) => {
        updateTemplate(event.target.value);
        updateDesignData({
            ...buttonDesignData,
            template: event.target.value,
        });
    };

    const { updateTouches } = useTouchContext('useButtonWidgetErrors');

    function renderSVG () {
        const Component = svgComponent(template.name, template.theme);
        return <Component
            width={buttonDesignData.width}
            text={buttonDesignData.text}
            fontColor={buttonDesignData.fontColour}
            backgroundColor={buttonDesignData.backgroundColour}
            onTextMatrixChange={(matrix) => {
                console.log('');
                console.log('');
                console.log('');
                console.log('Button Design Data:  ', buttonDesignData);
                console.log('Text Matrix: ', matrix);
                updateTextMatrix(matrix);
            }}
        />;
    }

    const handleButtonWidthChange = (e) => {
      updateTouches({ width: true });
      updateDesignData({
          ...buttonDesignData,
          width: e.target.value,
      });
    };

    const getButtonWidth = (val) => {
      let width = val;
      if (buttonDesignData.template === 'ecal-icon') {
        if (width > BUTTON_ICON_MAX_WIDTH) {
          width = BUTTON_ICON_MAX_WIDTH;
        }
        if (width < BUTTON_ICON_MIN_WIDTH) {
          width = BUTTON_ICON_MIN_WIDTH;
        }
      } else {
        if (width > BUTTON_MAX_WIDTH) {
          width = BUTTON_MAX_WIDTH;
        }
        if (width < BUTTON_MIN_WIDTH) {
          width = BUTTON_MIN_WIDTH;
        }
      }

      return width;
    };

    return (
        <Grid item justify={'center'} container>
            <Box
                display={'flex'}
                flexDirection={'column'}
                width={800}
                className={classes.boxStyling}
            >
                <Typography
                    variant={'h5'}
                    weight={'medium'}
                    style={{ marginBottom: 30 }}
                >
                    Design Button
                </Typography>
                {/* <Typography
                    // variant={'body1'}
                    // weight={'medium'}
                    block
                    style={{ marginBottom: 30 }}
                >
                    Placeholder to add info related to button design.
                </Typography> */}
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginBottom: 40,
                        alignItems: 'center',
                    }}
                >
                    <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%',
                        height: 120,
                        backgroundColor: '#eeeeee',
                        borderRadius: 5,
                        alignItems: 'center',
                    }}
                    >
                        <Box
                            sx={{
                                width: getButtonWidth(buttonDesignData.width) + 'px',
                                textAlign: 'center',
                                // bgcolor: buttonDesignData.backgroundColour,
                                // color: buttonDesignData.fontColour,
                            }}
                        >
                            { renderSVG() }
                        </Box>
                    </Box>
                </Box>
                <Box
                    sx={{
                        marginBottom: 59,
                    }}
                >
                    <Typography variant={'body1'} >
                        *Choose your shape:
                    </Typography>
                    <Grid container
                        spacing={2}
                        style={{
                            textAlign: 'center',
                            marginTop: '30px',
                        }}
                    >
                        <Grid item xs={3} style={{ textAlign: 'center' }}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    // textAlign: 'center',
                                    flexDirection: 'column',
                                }}
                            >
                                <Box
                                    sx={{
                                        width: '80%',
                                        height: 40,
                                        // border: '1px solid black',
                                        borderRadius: 25,
                                        marginBottom: 8,
                                    }}>
                                        <img src={pillImage} alt='pillImage' />
                                </Box>
                                <FormControlLabel
                                        style={{ marginBottom: 10 }}
                                    control={
                                        <Radio
                                        checked={buttonDesignData.template === 'ecal-pill'}
                                        onChange={handleTemplateChange}
                                        value='ecal-pill'
                                        color='default'
                                        name='radio-button-selected-schedules'
                                        inputProps={{ 'aria-label': 'pill shaped' }}
                                        icon={<RadioButtonUncheckedIcon fontSize='small' />}
                                        checkedIcon={<RadioButtonCheckedIcon fontSize='small' />}
                                        />
                                    }
                                    label={'Pill Shaped'}
                                />
                            </Box>

                        </Grid>
                        <Grid item xs={3} style={{ textAlign: 'center' }}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    // textAlign: 'center',
                                    flexDirection: 'column',
                                }}
                            >
                                <Box
                                    sx={{
                                        width: '80%',
                                        height: 40,
                                        // border: '1px solid black',
                                        borderRadius: 5,
                                        marginBottom: 8,
                                    }}><img src={roundedImage} alt='roundedImage' />
                                </Box>
                                <FormControlLabel
                                        style={{ marginBottom: 10 }}
                                    control={
                                        <Radio
                                            checked={buttonDesignData.template === 'ecal-round'}
                                            onChange={handleTemplateChange}
                                            value='ecal-round'
                                            color='default'
                                            name='radio-button-selected-schedules'
                                            inputProps={{ 'aria-label': 'rounded corners' }}
                                            icon={<RadioButtonUncheckedIcon fontSize='small' />}
                                            checkedIcon={<RadioButtonCheckedIcon fontSize='small' />}
                                        />
                                    }
                                    label={'Round Corners'}
                                />
                            </Box>

                        </Grid>
                        <Grid item xs={3} style={{ textAlign: 'center' }}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    // textAlign: 'center',
                                    flexDirection: 'column',
                                }}
                                style={{
                                    borderRight: '1px solid rgba(0, 0, 0, 0.23)',
                                }}
                            >
                                <Box
                                    sx={{
                                        width: '80%',
                                        height: 40,
                                        // border: '1px solid black',
                                        marginBottom: 8,
                                    }}><img src={squareImage} alt='squareImage' />
                                </Box>
                                <FormControlLabel
                                    style={{ marginBottom: 10 }}
                                    control={
                                        <Radio
                                            checked={buttonDesignData.template === 'ecal-square'}
                                            onChange={handleTemplateChange}
                                            value='ecal-square'
                                            color='default'
                                            name='radio-button-selected-schedules'
                                            inputProps={{ 'aria-label': 'square corners' }}
                                            icon={<RadioButtonUncheckedIcon fontSize='small' />}
                                            checkedIcon={<RadioButtonCheckedIcon fontSize='small' />}
                                        />
                                    }
                                label={'Square Corners'}
                                />
                            </Box>

                        </Grid>
                        <Grid item xs={2} style={{ textAlign: 'center' }}>
                        <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    // textAlign: 'center',
                                    flexDirection: 'column',
                                    marginLeft: 30,
                                }}
                            >
                                <Box
                                    sx={{
                                        width: 40,
                                        height: 40,
                                        // border: '1px solid black',
                                        marginBottom: 8,
                                    }}><img src={iconImage} alt='iconImage' />
                                </Box>
                                <FormControlLabel
                                    style={{ marginBottom: 10 }}
                                    control={
                                        <Radio
                                            checked={buttonDesignData.template === 'ecal-icon'}
                                            onChange={handleTemplateChange}
                                            value='ecal-icon'
                                            color='default'
                                            name='radio-button-selected-schedules'
                                            inputProps={{ 'aria-label': 'ecal-icon' }}
                                            icon={<RadioButtonUncheckedIcon fontSize='small' />}
                                            checkedIcon={<RadioButtonCheckedIcon fontSize='small' />}
                                        />
                                    }
                                label={'Icon'}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>

                <Grid container spacing={2} >
                    <Grid item xs={8} >
                        {/*  TODO : This is the Color Picker for the Background, we mess up here the design of palette should be separated from the picker */}
                        <ColorPicker
                            buttonColor={buttonDesignData.backgroundColour}
                            loadButtonColor = {true}
                            loadFontColor = {false}

                            onSelectTheme={(value) => {
                                selectTheme(value);
                            }}

                            setButtonColor={(value) => {
                                console.log('');
                                console.log('');
                                console.log('setButtonColor() this is custom...');
                                selectTheme('custom', value.hex);
                                /*
                                updateDesignData({
                                    ...buttonDesignData,
                                    backgroundColour: '#' + value.hex,
                                });
                                */
                            }}
                        />
                    </Grid>
                    <Grid item xs={4} >
                        <TextField
                            id='button-width'
                            label='Button Width'
                            name='buttonWidth'
                            type='number'
                            value={buttonDesignData.width}
                            onChange={handleButtonWidthChange}
                            variant='outlined'
                            style={{ marginBottom: 35 }}
                            helperText={errors.width || 'Width is set in pixels(px)'}
                            error={errors.width}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} style={{ display: (buttonDesignData.template === 'ecal-icon' || buttonDesignData.template === 'mobile-button') ? 'none' : 'flex' }}>
                    <Grid item xs={8} >
                        <TextField
                            id='button-text'
                            label='Button Text'
                            name='buttonText'
                            value={buttonDesignData.text}
                            onChange={(e) => {
                                updateDesignData({
                                    ...buttonDesignData,
                                    text: e.target.value,
                                });
                            }}
                            // disabled = {(buttonDesignData.template === 'ecal-icon') && true}
                            variant='outlined'
                            inputProps={{
                                maxLength: 20,
                            }}
                            style={{ marginBottom: 35 }}
                            helperText={'*Please enter a text, as you want it to appear on your button'}
                        />
                    </Grid>
                    <Grid item xs={4} >
                        <ColorPicker
                            buttonColor={buttonDesignData.backgroundColour}
                            loadCustomFontColor={true}
                            setButtonColor={(value) => {
                                updateDesignData({
                                    ...buttonDesignData,
                                    backgroundColour: '#' + value.hex,
                                });
                            }}

                            fontColor={buttonDesignData.fontColour}
                            setFontColor={(value) => {
                                updateDesignData({
                                    ...buttonDesignData,
                                    fontColour: '#' + value.hex,
                                });
                            }}
                        />
                    </Grid>
                </Grid>
                <TextField
                    id='displayID'
                    label='Display ID'
                    name='displayID'
                    value={buttonDesignData.name}
                    onChange={(e) => {
                        updateTouches({ name: true });
                        updateDesignData({
                            ...buttonDesignData,
                            name: e.target.value,
                        });
                    }}
                    variant='outlined'
                    style={{ marginBottom: 35 }}
                    helperText={ errors.name || '*Display ID is the name your button will be given within your Publisher Admin'}
                    error={errors.name}
                />

                <Box
                    display={'flex'}
                    justifyContent={'flex-end'}
                >
                     <ButtonProgress
                        disabled={saveDisabled}
                        showProgress={progress.save}
                        label='Save'
                        onClick={(e) => {
                            onSave();
                        }}
                    />
                </Box>
            </Box>
        </Grid>
    );
};

ButtonDesign.propTypes = {
    buttonDesignData: PropTypes.object,
    progress: PropTypes.object,
    saveDisabled: PropTypes.bool,
    updateDesignData: PropTypes.func,
    onSave: PropTypes.func,
    hasError: PropTypes.bool,
    hasInputError: PropTypes.bool,
    errors: PropTypes.object,
    setTouched: PropTypes.func,
    updateTextMatrix: PropTypes.func,
};

ButtonDesign.defaultProps = {
    buttonDesignData: {},
    updateDesignData: () => {},
    onSave: () => {},
    progress: {},
    saveDisabled: false,
    hasError: false,
    hasInputError: false,
    errors: {},
    setTouched: () => {},
    updateTextMatrix: () => {},
};

export default ButtonDesign;
