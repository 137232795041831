import { makeStyles } from '@material-ui/styles';
// import tinycolor from 'tinycolor2';

export default makeStyles(theme => ({

    colorPicker: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '190px',
        maxWidth: '280px',
        padding: '0px 16px 0px 16px',
        borderRadius: '4px',
        height: '56px',
        border: '1px solid #B2B2B2',
    },

    fontClrMargin: {
        marginLeft: '24px',
    },

    colorPickerText: {
        marginRight: '16px',
    },

}));
